import React, { Component } from 'react';
import axios from 'axios';
import { NavLink } from "react-router-dom";

export default class DemonstrativosFormularios extends Component {

    constructor( props ){
        
        super();

        this.state = {
            isLoaded: false,
            items : []
        }
    
    }

    componentDidMount () {

        const SERVER_URL = process.env.REACT_APP_SERVER_URL;

        axios
            .get( SERVER_URL + 'wp-json/bbprev/v1/demonstrativos-e-formularios?siteID=' + window.siteID )
            .then(( res ) => {
                this.setState({
                    items: res.data,
                    isLoaded: true
                })
            })
            .catch(
                err => console.log( err )
            )
        ;
  
    }

    render(){

        const { isLoaded, items } = this.state;
        if( !isLoaded || !items.length ) return null;
        
        const { siteData } = this.props;

        return (
            <div className="demonstrativos-e-formularios">
                <h4>{siteData.forms.title}</h4>
                <ul className="lista-botoes style-2">
                    {items.map(link=>
                        <li key={link.tid}>
                            <NavLink to={ '/demonstrativos-e-formularios/' + link.slug } className="arrow-right">
                                {link.name}
                            </NavLink>
                        </li>    
                    )}
                </ul>
            </div>
        )

    }

}