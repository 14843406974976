import React, { Component } from 'react';
import Beneficios from './Beneficios';
import { imageURL } from '../helpers/Common';
import { NavLink } from 'react-router-dom';

export default class Institucional extends Component {

    render(){

        const { name, description, slogan, beneficios, join, simule, adesao } = this.props.siteData;
        const internalJoin = typeof( join ) !== 'undefined' && join.enabled !== undefined && join.enabled === true;

        // Backgrounds
        const bgSimule = {
            backgroundImage: "url(" + imageURL( simule.cover ) + ")"
        };
        const bgAdesao = {
            backgroundImage: "url(" + imageURL( adesao.cover ) + ")"
        }

        // Simule title
        // var joinTitle = false;
        // if( internalJoin && internalJoin.title ){
        //     joinTitle = internalJoin.title;
        // }

        // Simulação
        var 
            simuleStatus = internalJoin,
            simuleType = 'internal'
        ;
        if( !simuleStatus ){
            if( simule.code !== undefined && simule.code !== '' ){
                simuleStatus = true;
                simuleType = 'code';
            }
            else if( simule.url !== undefined && simule.url !== '' ){
                simuleStatus = true;
                simuleType = 'external';
            }
        }

        // Adesão
        var 
            adesaoStatus = internalJoin,
            adesaoType = 'internal'
        ;
        if( !adesaoStatus ){
            if( adesao.code !== undefined && adesao.code !== '' ){
                adesaoStatus = true;
                adesaoType = 'code';
            }
            else if( adesao.url !== undefined && adesao.url !== '' ){
                adesaoStatus = true;
                adesaoType = 'external';
            }
        }
        
        // Link para adesao
        const linkAdesao = adesao.code ? 'https://www.bbprevidencia.com.br/cadastroweb/dispatcher/acessoIdentificado/' + adesao.code : false;
        
        return (
            <section className="institucional">
                
                <div className="presentation" id="o-plano">
                    <header>
                        <h2>O Plano {name}</h2>
                        <em>{slogan}</em>
                        <div>
                            <p>{description}</p>
                        </div>
                    </header>
                </div>
                
                { ( typeof( beneficios.items ) !== 'undefined' && beneficios.items.length ) && 
                <Beneficios data={beneficios} /> }

                { ( simuleStatus || adesaoStatus )
                ? <div id="simuladores" className="participe">
                    { ( simule.title !== undefined ) &&
                    <header>
                        <h3>{simule.title}</h3>
                    </header> }
                    { 
                    ( simuleStatus ) &&
                    <div>
                        { 
                        // Internal Simulator
                        simuleType === 'internal' &&
                        <NavLink to="/simulador" style={bgSimule}>
                            <button type="submit">
                                <div>
                                    <h4>Simule agora{/*simule.title*/}</h4>
                                    <em>Faça sua simulação e planeje seu futuro{/*simule.excerpt*/}</em>
                                </div>
                            </button>
                        </NavLink> }
                        { 
                        // Form code
                        simuleType === 'code' &&
                        <form action="https://www.bbprevidencia.com.br/simulador/dispatcher/adesao" method="post"  target="_blank" style={bgSimule}>
                            <input type="hidden" name="numeroSimulador" value={simule.code}></input>
                            { ( simule.patrocinadora !== undefined &&  simule.patrocinadora !== '' ) &&
                            <input type='hidden' name='numeroPatrocinadora'  value={simule.patrocinadora}></input> }
                            <button type="submit">
                                <div>
                                    <h4>Simule agora{/*simule.title*/}</h4>
                                    <em>Faça sua simulação e planeje seu futuro{/*simule.excerpt*/}</em>
                                </div>
                            </button>
                        </form> }
                        { 
                        // External URL
                        simuleType === 'external' &&
                        <a href={simule.url} target="_blank" rel="noreferrer" style={bgSimule}>
                            <button type="submit">
                                <div>
                                    <h4>Simule agora{/*simule.title*/}</h4>
                                    <em>Faça sua simulação e planeje seu futuro{/*simule.excerpt*/}</em>
                                </div>
                            </button>
                        </a> }
                    </div> }
                    { 
                    ( adesaoStatus ) &&
                    <div>
                        { 
                        // Internal Join
                        adesaoType === 'internal' && 
                        <NavLink to="/adesao" style={bgAdesao}>
                            <button type="submit">
                                <div>
                                    <h4>Faça a sua adesão{/*adesao.title*/}</h4>
                                    <em>Conheça os benefícios e inscreva-se{/*adesao.excerpt*/}</em>
                                </div>
                            </button>
                        </NavLink> }
                        { 
                        // Form code
                        ( adesaoType === 'code' ) &&
                        <form action={linkAdesao} method="get" target="_blank" style={bgAdesao}>
                            <button type="submit">
                                <div>
                                    <h4>Faça a sua adesão{/*adesao.title*/}</h4>
                                    <em>Conheça os benefícios e inscreva-se{/*adesao.excerpt*/}</em>
                                </div>
                            </button>
                        </form> }
                        {
                        // External URL
                        ( adesaoType === 'external' ) &&
                        <a href={adesao.url} target="_blank" rel="noreferrer" style={bgAdesao}>
                            <button type="submit">
                                <div>
                                    <h4>Faça a sua adesão{/*adesao.title*/}</h4>
                                    <em>Conheça os benefícios e inscreva-se{/*adesao.excerpt*/}</em>
                                </div>
                            </button>
                        </a> }
                    </div>
                    }
                </div>
                : '' }

            </section>
            
        )

    }

}