import React, { useContext } from 'react';
import Icons from '../helpers/Icons'
import * as Bootstrap from 'react-bootstrap';

function CustomToggle({ children, eventKey, callback, icon = 'arrowdown' }){

    const currentEventKey = useContext( Bootstrap.AccordionContext );
    const decoratedOnClick = Bootstrap.useAccordionToggle(
        eventKey,
        () => callback && callback( eventKey ),
    );
    const isCurrentEventKey = currentEventKey === eventKey;

    return (
        <button
            type="button"
            className={ isCurrentEventKey ? 'active' : '' }
            onClick={decoratedOnClick}
        >
            {children}
            {
                icon === 'plus'
                ? <i className="plus"></i>
                : <Icons tag={icon} />
            }
        </button>
    );
}

export default CustomToggle;