import React, { Component } from 'react';
import { withRouter } from "react-router";
import {Helmet} from 'react-helmet'
import PropTypes from 'prop-types';
//import ReactHtmlParser from 'react-html-parser';
import { API } from '../helpers/API';
import BeneficiosDestaques from '../blocks/BeneficiosDestaques';
import BeneficiosList from '../blocks/BeneficiosList';

export class ClubeDeBeneficios extends Component {

    static propTypes = {
        match: PropTypes.object.isRequired
    };

    constructor( props ){

        super();
        this.state = {
            isLoaded : false,
            data : false
        };
        

    }

    async componentDidMount(){

        const data = await API.Common.getPageData( 'blogbbp' );
        this.setState({
            data: data,
            isLoaded: true
        })
    }

    render() {

        const { isLoaded } = this.state;
        if( !isLoaded ) return null;

        var _title = 'Clube de Benefícios | BB Previdência';
        
        return (
            <div id="clube-de-beneficios" className="page clube-de-beneficios style---2">
                <Helmet>
                    <title>{_title}</title>
                    <meta property="og:title" content={_title} />
                </Helmet>
                <header className="cover">
                    <div className="content">
                        <div className="texts">
                            <h2>Clube de Benefícios</h2>
                            <div>
                                {/*ReactHtmlParser(data.post_content)*/}
                                <p>Neste começo de ano, nosso Clube de benefícios está cheio de ofertas para você se presentear, ou, presentear quem você ama. Confira e aproveite!</p>
                            </div>
                        </div>
                        <div className="image-wrapper">
                            <img src="https://assets-bbsegurosportal.bbseguros.com.br/styles/750x400/s3/2023-07/mes-dos-pais_mobile.jpg.webp?itok=5NgjDNra" alt=" " />
                        </div>
                    </div>
                    <div className="firula">
                        <div>
                            <svg version="1.1" x="0px" y="0px" viewBox="0 0 1446 970" width="1446" height="970" preserveAspectRatio="none">
                                <path d="M-0.000,33.000 C-0.000,33.000 80.000,54.000 113.000,83.000 C146.000,112.000 147.000,152.000 183.000,174.000 C219.000,196.000 288.000,172.000 334.000,193.000 C380.000,214.000 379.000,282.000 427.000,317.000 C475.000,352.000 548.000,336.000 588.000,371.000 C628.000,406.000 614.000,483.000 647.000,513.000 C680.000,543.000 786.000,537.000 841.000,587.000 C896.000,637.000 885.000,739.000 932.000,776.000 C979.000,813.000 1026.000,796.000 1069.000,817.000 C1112.000,838.000 1135.000,865.000 1189.000,893.000 C1243.000,921.000 1433.000,970.000 1433.000,970.000 L1446.000,0.000 L-0.000,33.000 Z"></path>
                            </svg>
                        </div>
                        <div>
                            <svg version="1.1" x="0px" y="0px" viewBox="0 0 1446 970" width="1446" height="970" preserveAspectRatio="none">
                                <path d="M-0.000,33.000 C-0.000,33.000 80.000,54.000 113.000,83.000 C146.000,112.000 147.000,152.000 183.000,174.000 C219.000,196.000 288.000,172.000 334.000,193.000 C380.000,214.000 379.000,282.000 427.000,317.000 C475.000,352.000 548.000,336.000 588.000,371.000 C628.000,406.000 614.000,483.000 647.000,513.000 C680.000,543.000 786.000,537.000 841.000,587.000 C896.000,637.000 885.000,739.000 932.000,776.000 C979.000,813.000 1026.000,796.000 1069.000,817.000 C1112.000,838.000 1135.000,865.000 1189.000,893.000 C1243.000,921.000 1433.000,970.000 1433.000,970.000 L1446.000,0.000 L-0.000,33.000 Z"></path>
                            </svg>
                        </div>
                    </div>
                </header>
                <BeneficiosDestaques />
                <BeneficiosList />
            </div>
        );
  
    }

}
  
export default withRouter( ClubeDeBeneficios );