import React, { Component } from 'react';
//import MetaTags from '../helpers/MetaTags';
import Contato from '../blocks/Contato.js';
import CanaisAtendimento from '../blocks/CanaisAtendimento.js';
import Breadcrumb from '../blocks/Breadcrumb';
export class Adesao extends Component {
    
    constructor( props ){

        super();

        this.state = {
            isLoaded: false
        }

    }

    render() {
        
        const { siteData } = this.props;

        const toCrumb = {
            path: false,
            label: 'Fale Conosco'
        };

        return (
            <div className="page blue single fale-conosco">
                <div className="wcf">
                    <Breadcrumb data={toCrumb} />
                    <section id="duvidas" className="duvidas">
                        <div className="wcf">
                            <Contato siteData={siteData} title="Fale conosco" />
                            <CanaisAtendimento data={siteData.canais} />
                        </div>
                    </section>
                </div>
            </div>
        );
  
    }
    
} 
export default Adesao;