import React, { Component } from 'react';
import axios from 'axios';
import { withRouter } from "react-router";
import PropTypes from 'prop-types';
import * as Bootstrap from 'react-bootstrap';

import Breadcrumb from '../blocks/Breadcrumb';
import Icons from '../helpers/Icons'
import CustomToggle from '../blocks/CustomToggle'

export class DemonstrativosFormularios extends Component {

    constructor( props ){

        super();
        
        this.state = {
            title: '',
            items: [],
            isLoaded: false
        }

    }

    static propTypes = {
        match: PropTypes.object.isRequired
    };
  
    componentDidMount () {

        const 
            SERVER_URL = process.env.REACT_APP_SERVER_URL,
            { match } = this.props
        ;

        axios
            .get( SERVER_URL + 'wp-json/bbprev/v1/demonstrativos-e-formularios/'+ match.params.groupID +'?siteID=' + window.siteID )
            .then(( res ) => {
                this.setState({
                    title: res.data.label,
                    items: res.data.items,
                    isLoaded: true
                })
            })
            .catch(
                err => console.log(err)
            )
        ;
  
    }

    render() {

        const 
            { title, items, isLoaded } = this.state,
            toCrumb = {
                path: '/#central-de-informacoes',
                label: 'Central de informações'
            }
        ;

        if( !isLoaded ) return null;
        
        let _key = 0;

        return (
            <div className="page blue demonstrativos-e-formularios">
                <div className="wcf">
                    <Breadcrumb data={toCrumb} />
                    <h2 className="page-title">{title}</h2>
                    <Bootstrap.Accordion defaultActiveKey="0">
                        {Object.keys( items ).map( itemKey => {
                            const item = items[ itemKey ];
                            _key = _key + 1;
                            return ( 
                                <Bootstrap.Card key={item.id}>
                                    <Bootstrap.Card.Header>
                                        <CustomToggle eventKey={_key}>{item.label}</CustomToggle>
                                    </Bootstrap.Card.Header>
                                    <Bootstrap.Accordion.Collapse eventKey={_key}>
                                        <Bootstrap.Card.Body>
                                            {item.items.map(subitem=>
                                                <a href={subitem.url} className="button" key={subitem.id} target="_blank" rel="noreferrer">
                                                    {subitem.label}
                                                    <Icons tag="download" />
                                                </a>
                                            )}
                                        </Bootstrap.Card.Body>
                                    </Bootstrap.Accordion.Collapse>
                                </Bootstrap.Card>
                            )
                        })}
                    </Bootstrap.Accordion>
                </div>
            </div>
        );
    
    }

}
  
export default withRouter( DemonstrativosFormularios );