import { Component } from 'react';
import Select2 from 'react-select2-wrapper';
//import SelectStyle from '../helpers/SelectStyle';

export default class ListFilters extends Component {

    constructor( props ){

        super( props );

        this.state = {
            lockSearch : true
        };

        this.keywordChange = this.keywordChange.bind( this );
        this.filterChange = this.filterChange.bind( this );
        this.bindSubmit = this.bindSubmit.bind( this );
    
    }

    keywordChange( e ){

        this.setState({
            lockSearch : e.target.value.length < 3
        });

        const { setValue } = this.props;

        setValue( e.target.name, e.target.value );

    }

    // Category change
    filterChange( e ){
        const { setValue } = this.props;
        setValue( e.target.name, e.target.value );
    }

    bindSubmit( e ){
        
        e.preventDefault();

        const { bindSubmit } = this.props;

        bindSubmit( e );

    }

    render(){

        const 
            { getValue, terms } = this.props,
            { lockSearch } = this.state
        ;

        return(
        <form className="right" onSubmit={this.bindSubmit}>
            { terms !== undefined &&
            <fieldset className="filters">
                <Select2
                    name="categoria"
                    id="categoria"
                    data={terms}
                    //defaultValue="0"
                    value={getValue('categoria')}
                    options = {{
                        minimumResultsForSearch: -1,
                    }}
                    onSelect={this.filterChange}
                />
            </fieldset>}
            <fieldset className="search" action="" method="get">
                <input type="text" placeholder="Faça uma busca" name="keyword" value={getValue( 'keyword' )} onChange={this.keywordChange} />
                <button type="submit" title="Buscar" disabled={lockSearch}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                        <path id="Search" d="M25.642,23.956l-4.449-4.417a10.79,10.79,0,1,0-1.667,1.668l4.413,4.417a1.192,1.192,0,1,0,1.7-1.668ZM12.8,21.207a8.4,8.4,0,1,1,8.4-8.4,8.4,8.4,0,0,1-8.4,8.4Z" transform="translate(-1.979 -1.979)" fill="#0059a5"/>
                    </svg>
                </button>
            </fieldset>
        </form>
        );

    }

}