import React, { Component } from 'react';
import { NavLink } from "react-router-dom";
import axios from 'axios';
import Icons from '../helpers/Icons';

export default class Documentos extends Component {

    constructor( props ){

        super();

        this.state = {
            isLoades: false,
            items: []
        }

    }

    componentDidMount(){

        const SERVER_URL = process.env.REACT_APP_SERVER_URL;

        axios.get( SERVER_URL + 'wp-json/wp/v2/documentos?siteID=' + window.siteID + '&_fields=id,title,file' )
          .then(res => this.setState({
              isLoaded: true,
              items: res.data
          }))
          .catch(err => console.log(err))
        ;
    
    }

    render(){

        const 
            { isLoaded, items } = this.state,
            { siteData } = this.props
        ;

        if( !isLoaded || !items.length ) return null;

        return (
            <div className="documentos">
                <h4>{siteData.documents.title}</h4>
                <ul className="lista-botoes">
                    {items.map(doc=>
                        <li key={doc.id}>
                            <a href={doc.file.url} target="_blank" rel="noreferrer">
                                <Icons tag={doc.file.extension} />
                                {doc.title.rendered}
                            </a>
                        </li>    
                    )}
                </ul>
                <NavLink to="/documentos" className="arrow-right">
                    Veja todos os documentos
                    <Icons tag="arrowright" />
                </NavLink>
            </div>
        )

    }

}