import React, { Component } from 'react';
import { NavLink } from "react-router-dom";
import Icons from '../helpers/Icons';

export class ClubeVantagens extends Component {

    constructor( props ){

        super( props )

        this.state = {
            isLoaded: false,
            isMobile: false,
            items: []
        }
    
    }

    render(){

        const { why, parceiros } = this.props;
        
        return(
            <section className="clube-de-vantagens">
                <div className="wcf">
                    <h3>{why.title}</h3>
                    { 
                    why.items 
                    ? <ul className="lista-motivos">
                        { why.items.map( motivo => 
                        <li key={motivo.key}>
                            <i className="icon"><Icons tag={motivo.icon} /></i>
                            <h4>
                                <strong>{motivo.big_text}</strong>
                                {motivo.small_text}
                            </h4>
                        </li> 
                        )}
                    </ul>
                    : null
                    }
                    {
                        parceiros.items
                        ? <div className="parceiros">
                            <h4>{parceiros.title}</h4>
                            <em>{parceiros.excerpt}</em>
                            <ul className="lista-logos">
                                { parceiros.items.map( logo => 
                                <li key={logo.key}>
                                    { 
                                    logo.url 
                                    ? <a href={logo.logo} target="_blank" rel="noreferrer"><img src={logo.logo} alt={logo.name} /></a> 
                                    : <img src={logo.logo} alt={logo.name} /> }
                                </li> 
                                )}
                            </ul>
                        </div>
                        : null
                    }
                    <NavLink to="/clube-de-beneficios" title="Clique para conhecer o clube de Benefícios" className="button default color--5"><span>Conhecer clube de Benefícios</span></NavLink>
                </div>
            </section>
        )

    }

}

export default ClubeVantagens;