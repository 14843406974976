import React, { Component } from 'react'
import Breadcrumb from '../blocks/Breadcrumb'
import { normalizeString } from '../helpers/Common'

export class Planos extends Component {

    constructor( props ){

        super();

        this.state = {
            items: [],
            keyword: '',
            errorText: '',
            matches: window.planos,
            fullMode: false
        }

        this.timeChange = false;
        this.timeResize = false;

    }

    componentWillUnmount(){
        window.removeEventListener( 'resize', this.checkSize );
    }
    
    extractStickies = () => {

        const planos = window.planos;

        let stickies = [];
        planos.forEach(function( plano ){
            if( plano.sticky !== null && plano.sticky > 0 ){
                stickies.push( plano );
            }
        });

        return !stickies.length ? false : stickies;
    
    }

    bindSubmit = () => {

        const planos = window.planos;
        const keyword = this.state.keyword.toLowerCase();

        let matches = [];

        planos.forEach(function( plano ){

            const planName = normalizeString(plano.name.toLowerCase())
            const keywordCompare = normalizeString(keyword)

            if( planName.includes( keywordCompare ) ){
                matches.push( plano );
            }
            else if( plano.searchkeys ) {
                plano.searchkeys.forEach(function( searchkey ){
                    if( searchkey.toLowerCase() === keyword ){
                        matches.push( plano );
                    }
                });
            }
            
        });

        this.setState({ 
            matches: matches,
            fullMode: true,
            errorText: !matches.length ? this.state.keyword : ''
        });

    }

    handleChange( e ){

        const keyword = e.target.value

        let newState = { keyword: keyword }

        this.setState( newState, () => {

            if( this.timeChange ) clearTimeout(this.timeChange)
            
                this.timeChange = setTimeout( () => {
                this.bindSubmit()
            }, 200)
   
        })
    
    }

    render() {

        const 
            { matches, errorText } = this.state,
            toCrumb = {
                path: false,
                label: 'Conheça os planos'
            },
            classNames = 'page planos with-results full-mode',
            emptyClass = 'no-results' + ( errorText === '' ? '' : ' show' )
        ;
        
        return (
            <div className={classNames}>
                <div className="wcf">
                    <Breadcrumb data={toCrumb} />
                    <div className="page-content">
                        <h2>Planos BB Previdência</h2>
                        <form onSubmit={this.bindSubmit}>
                            <input type="text" placeholder="Buscar por plano" onChange={this.handleChange.bind( this )} />
                            <button type="button" title="Buscar"></button>
                        </form>
                        <ul className="results">{
                            matches.length
                            ? matches.map( plano => {
                                const label = ( plano.sticky_label && plano.sticky_label !== '' ? plano.sticky_label : plano.name )
                                return (
                                <li key={plano.id}>
                                    <a href={ '/' + plano.sitekey } title="Acesspar plano">
                                        <h3>{label}</h3>
                                    </a>
                                </li>)
                            })
                            : null
                        }</ul>
                        <div className={emptyClass}>Não encontramos resultados para “{errorText}”. Experimente outra busca ou navegue pelos planos abaixo.</div>
                    </div>
                </div>
            </div>
        );
  
    }

}
  
export default Planos;