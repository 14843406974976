import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import axios from 'axios';
import * as Bootstrap from 'react-bootstrap';
import ReactHtmlParser from 'react-html-parser';
import CustomToggle from '../blocks/CustomToggle';
import Icons from '../helpers/Icons';

export class PerguntasFrequentes extends Component {

    constructor( props ){

        super( props )
    
        this.state = {
            isLoades: false,
            items: []
        }
        
      }
    
    componentDidMount(){

        const SERVER_URL = process.env.REACT_APP_SERVER_URL;

        axios.get( SERVER_URL + 'wp-json/wp/v2/perguntas-frequentes?siteID=' + window.siteID + '&per_page=7&_fields=id,slug,title,content' )
          .then(res => this.setState({
              isLoaded: true,
              items: res.data
          }))
          .catch(err => console.log(err))
        ;
        
    }

    render(){

        const { isLoaded, items } = this.state;
        if( !isLoaded || !items.length ) return null;

        // Verfify if has more items
        var hasMore = false;
        if( items.length > 6 ){
            hasMore = true;
            items.pop();
        }
        
        let _key = 0;
        
        return(
            <div className="perguntas-frequentes">
                {/* <h4>Perguntas frequentes</h4> */}
                <Bootstrap.Accordion defaultActiveKey="0">
                    {items.map( item => {
                        _key = _key + 1;
                        return ( 
                            <Bootstrap.Card key={item.id}>
                                <Bootstrap.Card.Header>
                                    <CustomToggle eventKey={_key} icon="plus">{item.title.rendered}</CustomToggle>
                                </Bootstrap.Card.Header>
                                <Bootstrap.Accordion.Collapse eventKey={_key}>
                                    <Bootstrap.Card.Body>
                                        {ReactHtmlParser(item.content.rendered)}
                                    </Bootstrap.Card.Body>
                                </Bootstrap.Accordion.Collapse>
                            </Bootstrap.Card>
                        )
                    })}
                </Bootstrap.Accordion>
                {
                hasMore
                ? <NavLink className="arrow-right" to="/perguntas-frequentes">
                    Veja todos as perguntas
                    <Icons tag="arrowright" />
                </NavLink>
                : null
                }
            </div>
        )
    
    }

}

export default PerguntasFrequentes;
