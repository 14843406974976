import React, { Component } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import { withRouter } from "react-router";
import { NavLink } from "react-router-dom";
import Teaser from '../blocks/Teaser';

export class CuradoriaLasts extends Component {

    static propTypes = {
        match: PropTypes.object.isRequired,
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired
    };

    constructor( props ){

        super( props )

        this.state = {
            isLoaded: false,
            isMobile: false,
            items: []
        }
    
    }

    componentDidMount () {

        const SERVER_URL = process.env.REACT_APP_SERVER_URL;

        axios.get( SERVER_URL + 'wp-json/wp/v2/curadoria?per_page=4&siteID=' + window.siteID )
            .then(res => this.setState({
                isLoaded: true,
                items: res.data
            }))
            .catch(err => console.log(err))
        ;
        
    }

    render() {

        const { items, isLoaded } = this.state;
        if( !isLoaded || !items.length ) return null;
        
        return (
            <section id="curadoria-lasts" className="teasers-list curadoria-lasts">
                <div>
                    <header>
                        <h3>Curadoria Blog BBP</h3>
                        <em>Conteúdos selecionados pelo nosso time de especialistas.</em>
                    </header>
                    <ul>
                        {items.map( (item ) => {
                            const url = '/blogbbp/curadoria/' + item.custom.slug;
                            return (
                            <Teaser key={item.custom.slug} data={item} url={url} type="2"></Teaser>
                            );
                        })}
                    </ul>
                    <NavLink to="/blogbbp/curadoria" className="button default" title="Ver todos os conteúdos">
                        Ver todos os Conteúdos
                    </NavLink>
                </div>
            </section>
        )
   
    }

}

export default withRouter( CuradoriaLasts );