import React, { Component } from 'react';
import axios from 'axios';
import Breadcrumb from '../blocks/Breadcrumb';
import * as Bootstrap from 'react-bootstrap';
import ReactHtmlParser from 'react-html-parser';
import CustomToggle from '../blocks/CustomToggle';

export class Documentos extends Component {

    state = {
        items: [],
        isLoaded: false
    }

    componentDidMount () {

        const SERVER_URL = process.env.REACT_APP_SERVER_URL;

        axios
            .get( SERVER_URL + 'wp-json/wp/v2/perguntas-frequentes?siteID=' + window.siteID + '&per_page=100&_fields=id,slug,title,content' )
            .then(( res ) => {
                this.setState({
                    items: res.data,
                    isLoaded: true
                })
            })
            .catch(
                err => console.log(err)
            )
        ;
  
    }

    render() {

        const 
            { items, isLoaded } = this.state,
            toCrumb = {
                path: '/#duvidas',
                label: 'Dúvidas'
            }
        ;
        if( !isLoaded ) return null;

        let _key = 0;

        return (
            <div className="page documentos">
                <div className="wcf">
                    <Breadcrumb data={toCrumb} />
                    <h2 className="page-title">Perguntas frequentes</h2>
                    <Bootstrap.Accordion defaultActiveKey="0">
                        {items.map( item => {
                            _key = _key + 1;
                            return ( 
                                <Bootstrap.Card key={item.id}>
                                    <Bootstrap.Card.Header>
                                        <CustomToggle eventKey={_key} icon="plus">{item.title.rendered}</CustomToggle>
                                    </Bootstrap.Card.Header>
                                    <Bootstrap.Accordion.Collapse eventKey={_key}>
                                        <Bootstrap.Card.Body>
                                            {ReactHtmlParser(item.content.rendered)}
                                        </Bootstrap.Card.Body>
                                    </Bootstrap.Accordion.Collapse>
                                </Bootstrap.Card>
                            )
                        })}
                    </Bootstrap.Accordion>
                </div>
            </div>
        );
  
    }

}
  
export default Documentos;