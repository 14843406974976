import React, { Component } from 'react';
import { API } from '../helpers/API';

export class LinksUteis extends Component {

    constructor( props ){

        super( props )

        this.state = {
            loading: true,
            isLoaded: false,
            items: []
        }
    
    }

    async componentDidMount () {

        const { siteID } = this.props;
        const response = await API.Common.getFooter( siteID );
        
        if( response.status === 'success' ){
            this.setState({
                loading: false,
                isLoaded: true,
                items: response.data.links_uteis,
            });
        }
        else {
            this.setState({
                error: response.message
            });
        }

    }

    render() {

        const { items } = this.state;
        if( !items ) return null;

        return (
            <div>
                <h2>Links Úteis</h2>
                <nav>
                    { items.map( ( lutil, i ) => {
                        return <a href={lutil.url} key={ 'lutil-'+ i } title={lutil.label} target={ lutil.blank === 'sim' ? '_blank' : '_self' } rel="noreferrer">{lutil.label}</a>
                    }) }
                </nav>
            </div>
        );
   
    }

}

export default LinksUteis;