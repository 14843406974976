import React, { Component } from 'react';
import {Helmet} from 'react-helmet'

export default class MetaTags extends Component {
    render(){

        const { siteData } = this.props;
        const API_URL = process.env.REACT_APP_API_URL;

        // Defaults
        const metaTags = ( siteData.seo === undefined ) ? {
            title: 'BB Previdência',
            description: 'Criada em 1994 e administrada pelo Banco do Brasil, a BB Previdência – Fundo de Pensão Banco do Brasil atua como fundo de pensão multipatrocinado, situação em que diversos planos de benefícios de diferentes empresas,  denominadas patrocinadoras, são administrados em conjunto, utilizando uma única infra-estrutura, sem que haja vínculo ou responsabilidade entre eles.',
            url: API_URL,
            image: API_URL + '/open-graph.png',
            tags: 'previdência, Banco do Brasil, aposentadoria'
        } : siteData.seo;

        // Title
        if( metaTags.title.indexOf( 'BB Previdência' ) < 0 ){
            metaTags.title += ' | BB Previdência'
        } 
        // Image
        if( !metaTags.image ){
            metaTags.image = API_URL + '/open-graph.png';
        }
        // URL
        metaTags.url = document.location.href;
        
        return(
            <Helmet>
                <title>{metaTags.title}</title>
                <link rel="canonical" href={metaTags.url} />
                <meta name="keywords" content={metaTags.tags} />
                <meta name="description" content={metaTags.description} />
                <meta property="og:type" content="website" />
                <meta property="og:url" content={metaTags.url} />
                <meta property="og:title" content={metaTags.title} />
                <meta property="og:description" content={metaTags.description} />
                <meta property="og:image" content={metaTags.image} />
                <meta property="og:image:alt" content={metaTags.title} />
                <meta name="twitter:card" content="summary" />
                <meta name="twitter:url" content={metaTags.url} />
                <meta name="twitter:title" content={metaTags.title} />
                <meta name="twitter:description" content={metaTags.description} />
                <meta name="twitter:image" content={metaTags.image} /> 
            </Helmet>
        );
    }
}
