import React, { Component } from 'react'
import axios from 'axios';
import ReactHtmlParser from 'react-html-parser';
import Slider from "react-slick";
import styled from "styled-components";
import $ from 'jquery';

import { imageURL } from '../helpers/Common';

export class Destaque extends Component {

    constructor( props ){

        super( props );

        this.data = {
            wrapper : React.createRef(),
            slider: React.createRef(),
            $ : false,
            $slider : false,
            $slides : false,
            videos : false,
            players: [],
            timeResize : false,
            configured: false
        };

        this.state = {
            items: [],
            activeSlide: 0,
            timeout: false,
            isLoaded: false
        };
    
    }

    componentDidMount () {      

        const SERVER_URL = process.env.REACT_APP_SERVER_URL;

        axios
            .get( SERVER_URL + 'wp-json/wp/v2/destaques?siteID=' + window.siteID + '&_fields=id,title,custom' )
            .then( ( res ) => {
                this.setState(
                    {
                        items: res.data,
                        isLoaded: true,
                    }, 
                    () => {
                        this.onInit()
                    }
                );
            })
            .catch( err => console.log( err ) )
        ;

    }

    onInit(){

        // API is not ready
        if( !window.YT ){
        
            const tag = document.createElement( 'script' );
            tag.src = 'https://www.youtube.com/iframe_api';
            window.onYouTubeIframeAPIReady = () => {
                this.configureVideos();
            };
            const firstScriptTag = document.getElementsByTagName( 'script' )[0];
            firstScriptTag.parentNode.insertBefore( tag, firstScriptTag );
  
        }
        // API was loaded
        else {

            this.configureVideos();

        }

    }

    // Configure vídeos
    configureVideos(){

        this.data.$ = $( this.data.wrapper.current );
        this.data.$slider = $( '.list-slides', this.data.$ );
        this.data.$slides = $( '.slide-item', this.data.$ );
        this.data.$videos = $( '.box-player', this.data.$slides );
        this.data.$slides.each( ( index ) => {

            var $slide = this.data.$slides.eq( index );
            
            var PData = {
                $slide : $slide,
                actual: false,
                disabled : false,
                configured: false,
                forcedPause : false,
                index : index,
                videoCode : false,
                $video : $( 'video', $slide ),
                $button : $( 'a.controll-button', $slide )
            };

            if( PData.$video.length ){
                
                PData.videoCode = PData.$video.attr( 'data-video-code' );

                // Configure vídeo
                //this.configureVideo( PData );

                PData.$button.on( 'mouseup', ( e ) => {
                    e.preventDefault();
                    this.startVideo( PData );					
                });
            
            }

            $slide.data( 'PikiSlider', PData );

        });
        
    }

    // Binda play button
    bindClickVideo ( PData ) {
        try {
            const videoState = PData.player.getPlayerState();
            if( videoState !== window.YT.PlayerState.PLAYING && videoState !== window.YT.PlayerState.UNSTARTED ){
                this.startVideo( PData );
            }
        }
        catch( e ){
            console.log( e )
        }
    }

    // Starging a video
    startVideo( PData ){
        if( PData.configured === false ){
            this.configureVideo( PData );
        }
        else {
            PData.player.playVideo();
        }
        PData.actual = true;
    }

    // Configure video
    configureVideo ( PData ){

        // Have no video
        if( PData.videoCode === false ){
            return;
        }

        // Inicia o vídeo
        PData.player = new window.YT.Player( PData.$video.attr( 'id' ), {
            
            height: '100%',
            width: '100%',
            videoId: PData.videoCode,
            playerVars: { 
                autoplay : 1, 
                rel : 0,
                enablejsapi : 1,
                modestbranding : 1,
                showinfo : 0
            },
            
            events: {
                onReady: function( event ){
                    PData.videoReady = true;
                    PData.$button.fadeIn();
                },
                onStateChange : ( event ) => {
                    this.videoStateChange( event, PData );
                }
            }
        
        });
        
        this.data.players.push( PData );

        PData.configured = true;

        return PData;

    }

    // On video state change
    videoStateChange( event, PData ){

        // Video state
        var state = event.target.getPlayerState();

        // Se o vídeo começa a reprodução, mas não é o slide atual
        if( state === window.YT.PlayerState.PLAYING ){
            if( this.state.activeSlide !== PData.index ){
                event.target.pauseVideo();
                PData.forcedPause = true;
            }
            else {
                PData.forcedPause = false;
            }
        }

        // Botão de play
        if( state === window.YT.PlayerState.PAUSED || state === window.YT.PlayerState.ENDED ){
            PData.$slide.removeClass( 'playing' );
            PData.disabled = false;
        } 
        else {
            PData.$slide.addClass( 'playing' );
            PData.disabled = true;
        }

    }

    // Restart video forced pause
    resumeVideo(){

        // Pausing all videos
        this.pauseVideos();

        // Resuming actual
        var $slide = this.data.$slides.eq( this.state.activeSlide );
        var PData = $slide.data( 'PikiSlider' );
        if( PData !== undefined && PData.forcedPause === true ){
            PData.player.playVideo();
        }

    }

    // Pause all videos
    pauseVideos(){

        // Total de vídeos
        var total = this.data.players.length;
        
        // No videos
        if( total < 1 ) return;
        
        // Pausing videos
        for ( var i = 0; i < total; i++ ) {
            
            var data = this.data.players[ i ];
            
            if( data.player.getPlayerState === undefined ){
                continue;
            }

            if( data.player.getPlayerState() === window.YT.PlayerState.PLAYING ){
                data.forcedPause = true;
                data.player.pauseVideo();
            }
        
        }
    
    }

    beforeSlideChange( current, next ){
        this.pauseVideos();
        this.setState({ oldSlide: current, activeSlide: next })
    }

    afterSlideChange( current ){
        this.setState({ activeSlide: current });
        this.resumeVideo();
    }

    render() {

        const { items } = this.state;
        if( !items.length ) return null;
        
        const slickSettings = {
            slide: '.slide-item',
            infinite: false,
            adaptiveHeight: false,
            pauseOnDotsHover: true,
            dotsClass: 'slider-pager',
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: true,
            dots: true,
            beforeChange: ( current, next ) => {
                this.beforeSlideChange( current, next );
            },
            afterChange: ( current ) => {
                this.afterSlideChange( current );
            }
        };

        return (
            <div ref={this.data.wrapper} className="banner-principal">
                <Slider ref={this.data.slider} className="list-slides" {...slickSettings}>
                    {items.map(item => {

                        const { title, destino, media } = item.custom;

                        const desktopImage = imageURL( media.desktop );
                        const mobileImage = media.mobile !== '' ? imageURL( media.mobile ) : false;
                        
                        // Background image
                        const BgItem = styled.div`
                            display: flex;
                            background-image: url(${desktopImage});
                            ${mobileImage !== ''
                            ? `@media(max-width:580px){background-image:url(${mobileImage})}`
                            : ``}
                        `;

                        // Button
                        let Button = false;
                        if( destino.url !== '' && destino.label ){
                            Button = styled.a`
                                color: ${destino.color === '' ? '#ffffff' : destino.color };
                                background-color: ${destino.bg === '' ? '#00315D' : destino.bg };
                            `;
                        }
                        
                        // Vídeo
                        const Video = ( media.video.code !== '' ) ? media.video.widget + '<a class="controll-button" title="Assistir vídeo"></a>' : '';

                        // Title
                        const TitleTag = styled.code`
                            color: ${ title.color === '' ? '#ffffff' : title.color };
                            ${title.bg !== '' ? `background-color: ${ title.bg };` : `` }
                        `;

                        // Check if is all slide area clicable
                        let 
                            MainTag,
                            url,
                            target,
                            classses = 'slide-item align-' + title.align + ' valign-' + title.valign + ( Video ? ' with-video' : '' ) + ( media.mask !== false ? ' with-mask' : '' ) + ( title.shadow !== false ? ' title-shadow' : '' )
                            + ( title.bg !== '' ? ' title-bg' : '' )
                        ;
                        if( !Video && destino.url !== '' && destino.label === '' ){
                            MainTag = 'a';
                            url = destino.url;
                            target = destino.target === '_blank' ? '_blank' : null;
                        }
                        else {
                            MainTag = 'div';
                            url = null;
                            target = null;
                        }

                        return (
                            <MainTag key={item.id} className={classses} href={url} target={target}>
                                {ReactHtmlParser(Video)}
                                <BgItem className="cover" />
                                <div className="content">
                                    {title.text !== '' &&
                                    <h4>
                                        <TitleTag>{ReactHtmlParser(title.text)}</TitleTag>
                                    </h4>}
                                    {Button &&
                                    <Button href={destino.url} target={ target ? 'target="_blank" rel="noreferrer"' : null } title={destino.label}>{destino.label}</Button> }
                                </div>
                            </MainTag>
                        );
                    
                    })}
                </Slider>
            </div>
        );

    }

}

export default Destaque
