import React, { Component } from 'react';

export class Contato extends Component {

    componentDidMount(){

        const neoPlano = this.props.siteData.name;

        window.NeoAssistTag = {};
        window.NeoAssistTag.querystring = true;
        window.NeoAssistTag.pageid = '';
        window.NeoAssistTag.clientdomain = 'bbprevidencia.neoassist.com';
        window.NeoAssistTag.initialize = {
            fields: {
                ProtocoloFieldfieldA: neoPlano,
                ProtocoloFieldB: ''
            }
        };
        var na = document.createElement('script'); 
        na.type = 'text/javascript'; 
        na.async = true; 
        na.src = 'https://cdn.atendimen.to/n.js'; 
        var s = document.getElementsByTagName( 'script' )[ 0 ]; 
        s.parentNode.insertBefore( na, s ); 
    
    }

    render(){

        const title = this.props.title === undefined ? 'Fale Conosco' : this.props.title;

        return(
            <div className="contato">
                <div className="inner">
                    <h4>{title}</h4>
                    <div id="NeoassistCentral"></div>
                </div>
            </div>
        )
    }

}

export default  Contato;