import React from 'react';
import ReactHtmlParser from 'react-html-parser';

import Icons from '../helpers/Icons';
import Subpages from './Subpages';

export class Subpage extends React.Component {

    render(){

        const { page } = this.props;

        return(
            <div className="subpage" data-page-id={page.id} key={page.id}>
                <h3>{page.title}</h3>
                { 
                page.content !== '' || page.files 
                ? <div className="content">
                    {ReactHtmlParser(page.content)}
                    {
                    page.files
                    ? <ul className="list-files">
                        {page.files.map(file=>{
                            return (
                                <li key={file.id}>
                                    <a href={file.url} target="_blank" rel="noreferrer">
                                        <Icons tag={file.extension} />
                                        {file.title}
                                    </a>
                                </li>
                            )
                        })}
                    </ul>
                    : null
                    }
                </div>
                : null
                }
                {
                    page.subpages
                    ? <Subpages data={page.subpages} />
                    : null
                }
            </div>
        )

    }


}

export default Subpage