import React, { Component } from 'react'

import MetaTags from '../helpers/MetaTags';

import Destaque from '../blocks/Destaque';
import Institucional from '../blocks/Institucional';
//import Noticias from '../blocks/Noticias';
import ClubeVantagensPlano from '../blocks/ClubeVantagensPlano';
import Comunicados from '../blocks/Comunicados';
//import EducacaoFinanceira from '../blocks/EducacaoFinanceira';
import BlogBBP from '../blocks/BlogBBP';
import CentralInformacoes from '../blocks/CentralInformacoes';
import Duvidas from '../blocks/Duvidas';

export class Plano extends Component {

    render() {

        const { siteData } = this.props;       

        // const why = {
        //     title : 'Um Clube de Benefícios exclusivo para você',
        // };

        const blogData = siteData?.pensefuturo !== undefined ? siteData.pensefuturo : siteData.blogbbp;

        return (
            <div className="home">
                <MetaTags siteData={siteData} />
                <Destaque />
                <Institucional siteData={siteData} />
                <ClubeVantagensPlano parceiros={siteData.parceiros} />
                {/* <Noticias siteData={siteData} /> */}
                <Comunicados />
                <BlogBBP data={siteData} />
                {/* <EducacaoFinanceira siteData={siteData.educativos} /> */}
                <CentralInformacoes siteData={siteData} />
                <Duvidas siteData={siteData} />
            </div>
        );

    }

}

export default Plano