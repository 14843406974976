import React, { Component } from 'react';
import { withRouter } from "react-router";
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';
import axios from 'axios';
import * as Bootstrap from 'react-bootstrap';
import ReactHtmlParser from 'react-html-parser';

import { stringSearch } from '../helpers/Common';
import CustomToggle from '../blocks/CustomToggle';
import ListFilters from '../forms/ListFilters';

export class Glossario extends Component {

    static propTypes = {
        match: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired
    };

    state = {
        items: [],
        filtered : [],
        values : {
            keyword : ''
        },
        filters : {
            keyword : ''
        },
        showActives : false,
        locked : true,
        isLoaded: false
    };

    constructor(props){

        super(props);
    
        this.getValue = this.getValue.bind(this);
        this.setValue = this.setValue.bind( this );
        this.bindSubmit = this.bindSubmit.bind(this);
        this.clearFilter = this.clearFilter.bind( this );
    
    }

    getValue( key ){
        const { values } = this.state;
        return values[ key ];
    }

    setValue( key, value ){

        var { values } = this.state;

        values[ key ] = value;

        this.setState({
            values : values
        });

    }

    bindSubmit( event ){

        event.preventDefault();

        const { values, items } = this.state;
        
        if( values.keyword.length < 3 ){

            this.setState({
                filtered : items
            });

        }
        // Filtering
        else {
        
            let newlist = [];
            for( let g = 0; g < items.length; g++ ){

                let group = [];
                for( let i = 0; i < items[ g ].items.length; i++ ){
                    
                    const item = items[ g ].items[ i ];

                    if( stringSearch( item.title.rendered, values.keyword ) || stringSearch( item.content.rendered, values.keyword ) ){
                        group.push( item );
                    }
                
                }
                
                // If math
                if( group.length ){
                    items[ g ].items = group;
                    newlist.push( items[ g ] );
                }
                
            }

            this.setState({
                filters : {
                    keyword : values.keyword
                },
                filtered : newlist,
                showActives : true
            });
            
        }

    }

    clearFilter(){

        var { items } = this.state;

        this.setState({
            filtered : items,
            showActives : false,
            values : {
                keyword : ''
            }
        });

    }

    componentDidMount() {

        const SERVER_URL = process.env.REACT_APP_SERVER_URL;

        axios
            .get( SERVER_URL + 'wp-json/wp/v2/glossarios?per_page=100&_fields=id,slug,title,content' )
            .then(( res ) => {
                this.receiveData( res );
            })
            .catch(
                err => console.log(err)
            )
        ;

    }

    receiveData( response ){

        var indexed = [];

        for( var i=0; i < response.data.length; i++ ){

            const item = response.data[i];
            const firstLetter = response.data[i].title.rendered.substring( 0, 1 ).toUpperCase();

            if( indexed[ firstLetter ] === undefined ){
                indexed[ firstLetter ] = {
                    letter : firstLetter,
                    items : []
                };
            }
            indexed[ firstLetter ].items.push( item );
            
        }

        var 
            keys = Object.keys( indexed ).sort(),
            items =  []
        ;
        for( var k = 0; k < keys.length; k++  ){
            items.push( indexed[ keys[ k ] ] );
        }

        this.setState({
            items : items,
            filtered : items,
            isLoaded: true
        })
    
    }

    render() {

        const 
            { filtered, filters, showActives, isLoaded } = this.state,
            { history } = this.props
        ;
        if( !isLoaded ) return null;

        let _key = 0;

        return (
            <div className="page content-list glossario">
                <Helmet>
                    <title>{ 'Glossário | BB Previdência' }</title>
                    <meta property="og:title" content='{Glossário}' />
                </Helmet>
                <header>
                    <div>
                        <div className="left">
                            <nav className="crumbs">
                                <button className="active" onClick={ () => { history.push('/'); } }>Home</button>
                                <svg xmlns="http://www.w3.org/2000/svg" width="7.137" height="8.343" viewBox="0 0 7.137 8.343"><path className="a" d="M4.842-7.61a4.329,4.329,0,0,0-.927-.324,4.329,4.329,0,0,0,.927-.324L8.109-9.941a.661.661,0,0,0,.3-.252.645.645,0,0,0,.09-.333V-12.11L1.359-8.384v.9L8.5-3.767V-5.351a.622.622,0,0,0-.09-.329.728.728,0,0,0-.3-.256Z" transform="translate(8.496 -3.767) rotate(180)"></path></svg>
                                <strong>Glossário</strong>
                            </nav>
                            <button className="styled size--2 color--2 back" onClick={history.goBack} title="Voltar">
                                <svg xmlns="http://www.w3.org/2000/svg" width="11.995" height="12.01" viewBox="0 0 11.995 12.01"><path id="Arrow" d="M17,11H9.41l3.3-3.29a1,1,0,1,0-1.42-1.42l-5,5a1.034,1.034,0,0,0,0,1.42l5,5a1,1,0,1,0,1.42-1.42L9.41,13H17a1,1,0,0,0,0-2Z" transform="translate(-6.005 -5.996)" fill="#0059a5"/></svg>
                                <span>Voltar</span>
                            </button>
                            <h2>Glossário</h2>
                        </div>
                        <ListFilters
                            getValue={this.getValue}
                            setValue={this.setValue}
                            bindSubmit={this.bindSubmit}
                        />
                    </div>
                </header>
                <Bootstrap.Accordion defaultActiveKey="0" className="indexed">
                    { ( filters.keyword !== '' && showActives ) && 
                    <div className="active-filters">
                        <em>Mostrando resultados para <strong>“{filters.keyword}”</strong></em>
                        <button className="inline" title="Limpar filtro" onClick={this.clearFilter}>Limpar filtro</button>
                    </div>}
                    {filtered.map( group => {
                        return(
                            <React.Fragment key={'group-'+_key}>
                                <em className="indice">{group.letter}</em>
                                {group.items.map( item => {
                                    _key++;
                                    return(
                                    <React.Fragment key={'item-'+item.id}>
                                        <Bootstrap.Card key={item.id}>
                                            <Bootstrap.Card.Header>
                                                <CustomToggle eventKey={_key} icon="plus">{item.title.rendered}</CustomToggle>
                                            </Bootstrap.Card.Header>
                                            <Bootstrap.Accordion.Collapse eventKey={_key}>
                                                <Bootstrap.Card.Body>
                                                    {ReactHtmlParser(item.content.rendered)}
                                                </Bootstrap.Card.Body>
                                            </Bootstrap.Accordion.Collapse>
                                        </Bootstrap.Card>
                                    </React.Fragment> );
                                })}
                            </React.Fragment>
                        );
                    })}
                </Bootstrap.Accordion>
            </div>
        );
  
    }

}
  
export default withRouter( Glossario );