import React from 'react';
import * as Bootstrap from 'react-bootstrap';
import ReactHtmlParser from 'react-html-parser';

import CustomToggle from '../blocks/CustomToggle';

export class Accordion extends React.Component {

    render(){
        
        const { items } = this.props;

        var _key = 0;

        return(
            <Bootstrap.Accordion defaultActiveKey="0">
                {items.map( item => {
                    _key = _key + 1;
                    return ( 
                        <Bootstrap.Card key={item.id}>
                            <Bootstrap.Card.Header>
                                <CustomToggle eventKey={_key} icon="plus">{item.title}</CustomToggle>
                            </Bootstrap.Card.Header>
                            <Bootstrap.Accordion.Collapse eventKey={_key}>
                                <Bootstrap.Card.Body>
                                    {ReactHtmlParser(item.content)}
                                </Bootstrap.Card.Body>
                            </Bootstrap.Accordion.Collapse>
                        </Bootstrap.Card>
                    )
                })}
            </Bootstrap.Accordion>
        )

    }

}

export default Accordion