import { Component } from 'react';
import { withRouter } from "react-router";
import PropTypes from 'prop-types';
import $ from 'jquery';

import ScrollTo from './ScrollTo';

export class Navigation extends Component {
  
    constructor( props ){

        super();
  
        this.state = {
            isLoaded: false
        }

        this.data = {
            $body: null,
            $menu : null,
            $header : null,
            $triggers : null,
            timeout : false,
            targets : {},
            totalTargets : 0,        
            timeWait : false
        }

    }

    static propTypes = {
        match: PropTypes.object.isRequired
    };

    checkChanges( prevProps = false ){
        
        $( '#piki-loader' ).fadeOut( 400 );

        let { hash, pathname } = this.props.location;
        var $target = $( hash );
        
        if( $target.length ){
            ScrollTo( $target );
        }
        else {
            if( prevProps && pathname !== prevProps.location.pathname ){
                ScrollTo( this.data.$body );
            }
        }
        
    }

    componentDidMount (){

        if( this.state.Loaded ) return null;

        this.setState({
            isLoaded: true
        }, () => {
            
            this.data.$body = $( 'body' );
            this.data.$header = $( '#masthead' );
            this.data.$menu = $( '#menu' );
            this.data.$triggers = $( 'a', this.data.$menu );

            const { hash } = this.props.location;
            if( hash !== '' ){

                this.timeWait = setTimeout(
                    () => {
                        const $target = $( hash );
                        if( $target.length ){
                            window.scrollTo( 0, 0 );
                            ScrollTo( $target );
                        }
                    },
                    500
                );
            }
            
            // setTimeout( () => {
            //     this.bind();
            // }, 100 );

        });

    }

    componentDidUpdate( prevProps ) {
        
        let { pathname } = this.props.location;
        const isSamePage = ( pathname === prevProps.location.pathname );
        if( this.timeWait ) clearTimeout( this.timeWait );
        this.timeWait = setTimeout( () => { this.checkChanges( prevProps ) }, ( isSamePage ? 10 : 400 ) );
    
    }
    
    componentWillUnmount() {
        window.removeEventListener( 'scroll', this.checkScroll );
    }
    
    bind () {

        const __this = this;
        window.addEventListener( 'scroll', function(){
            __this.checkScroll();
        }, true );

        // Targets
        if( this.data.$menu.length && this.data.$triggers.length ){

            // Trigger click
            this.data.$triggers.on( 'click', function(){
                this.$ = this.$ || $( this );
                __this.clickMenuItem( this );	    			
            });

            this.data.$triggers.each(function(){

                this.$ = this.$ || $( this );
                let 
                    targetID = __this.getTargetHash( this.$ ),
                    $target = false
                ;
                // If target is home
                if( targetID === '#' || targetID === '/'+ window.siteKey +'/' ){
                    $target = this.data.$body;
                }
                // Search target
                else {
                    $target = $( targetID );
                }

                if( $target.length ){

                    var targetTop = $target.offset().top;
                    __this.data.targets[ targetTop ] = $target;
                    __this.data.totalTargets++;

                }
                
            });

        }

    }

    getTargetHash( $element ){
        let 
            targetID = false,
            href = $element.attr( 'href' )
        ;
        if( href.indexOf( '#' ) === 0 ){
            targetID = href;
        }
        else if( href.indexOf( '#' ) > -1 ){
            targetID = '#' + href.split( '#' ).pop();
        }
        return targetID;
    }

    clickMenuItem( item ){

        const _href = item.$.attr( 'href' );		

        this.data.$triggers.removeClass( 'active' );
        this.data.$triggers.filter( '[href="'+ _href +'"]' ).addClass( 'active' );

    }

    checkIfBottom(){

        var 
            pageHeight = this.getPageHeight(),
            pageTop = ( window.pageYOffset || document.documentElement.scrollTop )  - ( document.documentElement.clientTop || 0 ),
            viweportHeight = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0)
        ;

        return pageTop >= ( pageHeight - viweportHeight - 100 );


    }

    getPageTop(){

        let top = ( window.pageYOffset || document.documentElement.scrollTop )  - ( document.documentElement.clientTop || 0 );
        if( this.data.$header.length ){
            top += this.data.$header.outerHeight();
        }
        
        return  top;
    
    }

    getPageHeight(){

        const doc = document;
        return  Math.max(
            doc.body.scrollHeight, doc.documentElement.scrollHeight,
            doc.body.offsetHeight, doc.documentElement.offsetHeight,
            doc.body.clientHeight, doc.documentElement.clientHeight
        );
    }

    checkScroll(){
        
        this.data.$triggers.removeClass( 'active' );

        var 
            start = Object.keys( this.data.targets ).shift(),
            top = this.getPageTop(),
            $target = false
        ;

        if( top <= start ){

            $target = this.data.$triggers.first();

        }
        else if( this.checkIfBottom() ){

            $target = this.data.$triggers.last();

        }
        else {

            $.each( this.data.targets, function(){

                this.$ = this.$ || $( this );
                
                var pos = this.offset().top;
                if( top >= ( pos - start ) ){
                    $target = this.$;
                }
            
            });

        }

        if( $target ){

            const 
                __this = this,
                _id = '#' + $target.attr( 'id' )
            ;

            this.data.$triggers.map( i => {
                let $trgr = __this.data.$triggers.eq( i );
                const hash = __this.getTargetHash( $trgr );
                if( hash === _id ){
                    $trgr.addClass( 'active' );
                }
                return null;
            });
            
        }

    }

    vWidth(){
        const vWidth = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
        return vWidth;
    }

    render() {
        return null;
    }

}

export default withRouter( Navigation );