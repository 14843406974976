import React, { Component } from 'react';
import { withRouter } from "react-router";
import { NavLink } from "react-router-dom";
import {Helmet} from 'react-helmet'
import PropTypes from 'prop-types';
import axios from 'axios';
import ReactHtmlParser from 'react-html-parser';
import Moment from 'moment';
import 'moment/locale/pt-br';

import { Icons } from "../helpers/Icons";

export class Comunicado extends Component {
  
  constructor( props ){

    super();
  
    this.state = {
      comunicado: [],
      isLoaded: false
    }

  }

  static propTypes = {
    match: PropTypes.object.isRequired
  };
  
  getData( comunicadoId ){

    if( comunicadoId === undefined  ){
      comunicadoId = this.props.firstID;
    }

    const SERVER_URL = process.env.REACT_APP_SERVER_URL;

    axios
      .get( SERVER_URL + 'wp-json/wp/v2/comunicados?slug=' + comunicadoId + '&siteID='+ window.siteID +'_fields=id,slug,title,date' )
      .then(res => this.setState({
        comunicado: res.data.shift(),
        isLoaded: true
      }))
      .catch(
        err => console.log(err)
      )
    ;
    
  }

  componentDidMount () {
    const { match } = this.props;
    this.getData( match.params.comunicadoId );
  }

  componentWillReceiveProps( nextProps ){
      const { match } = nextProps;
      this.getData( match.params.comunicadoId );
  }

  render() {

    const { comunicado, isLoaded } = this.state;
    const { isMobile } = this.props;
    if( !isLoaded ) return null;

    var _title = comunicado.title.rendered + ' | Comunicados';
    var _description = comunicado.content.rendered.replace( /<[^>]+>/g, '' );

    return (
      <article className="comunicado">
        <Helmet>
            <title>{_title}</title>
            <meta property="og:title" content={_title} />
            <meta property="og:description" content={_description} />
            <meta name="twitter:title" content={_title} />
            <meta name="twitter:description" content={_description} />
        </Helmet>
        { 
        isMobile 
        ? <NavLink to="/comunicados">Fechar</NavLink>
        : null
        }
        <h2>{comunicado.title.rendered}</h2>
        <em>
          <Icons tag="calendar" />
          {Moment(comunicado.date).format('LL')}
        </em>
        <div>
          {ReactHtmlParser(comunicado.content.rendered)}
        </div>
      </article>
    );
  
  }

}

export default withRouter( Comunicado );
