import React, { Component } from 'react';
import axios from 'axios';
import { withRouter } from "react-router";
import { Switch, Route, NavLink } from "react-router-dom";
import {Helmet} from 'react-helmet'
import PropTypes from 'prop-types';
import Moment from 'moment';
import PerfectScrollbar from 'react-perfect-scrollbar'

import Breadcrumb from '../blocks/Breadcrumb';
import Comunicado from '../blocks/Comunicado';

export class Comunicados extends Component {

    constructor( props ){

        super();

        this.state = {
            items: [],
            firstID: false,
            isLoaded: false,
            isMobile: false
        }

        this.timeResize = false;

    }

    static propTypes = {
        match: PropTypes.object.isRequired
    };
  
    componentDidMount () {

        this.checkSize();
        window.addEventListener( 
            'resize', 
            () => {
                if(  this.timeResize ) clearTimeout( this.timeResize );
                this.timeResize = this.checkSize();
            }, 
            true 
        );

        const SERVER_URL = process.env.REACT_APP_SERVER_URL;
        axios
            .get( SERVER_URL + 'wp-json/wp/v2/comunicados?siteID=' + window.siteID + '&_fields=id,slug,title,date' )
            .then(( res ) => {

                const { data } = res;

                this.setState({
                    items: data,
                    firstID: data[ 0 ].slug,
                    isLoaded: true
                })
      
            })
          .catch(
              err => console.log(err)
          )
      ;
  
    }

    componentWillUnmount(){
        window.removeEventListener( 'resize', this.checkSize );
    }

    checkSize = () => {
        const width = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
        this.setState({
            isMobile: width <= 850
        })
    }

    render() {

        const 
            { items, firstID, isLoaded, isMobile } = this.state,
            { match } = this.props,
            toCrumb = {
                path: '/comunicados',
                label: 'Comunicados'
            }
        ;

        if( !isLoaded ) return null;

        const 
            baseurl = match.path.split(  '/:comunicadoID' ).join( '' ),
            comunicadoID = match.params.comunicadoID
        ;

        var _title = 'Comunicados | BB Previdência';

        return (
            <div className="page comunicados with-menu wcf">
                <Helmet>
                    <title>{_title}</title>
                    <meta property="og:title" content={_title} />
                </Helmet>
                <Breadcrumb data={toCrumb} />
                { !isMobile || comunicadoID === undefined
                ? <div className="list-items">
                    <PerfectScrollbar>
                        <ul>
                            {items.map( item =>
                            <li key={item.id}>
                                <NavLink to={ baseurl + '/' + item.slug } activeClassName='is-active'>
                                    <h3>{item.title.rendered}</h3>
                                    <em>{Moment(item.date).format('DD/MM/YYYY')}</em>
                                </NavLink>
                            </li>
                          )}
                      </ul>
                    </PerfectScrollbar>
                  </div>
                : null 
                }
                <Switch>
                    <Route exact path={baseurl}>
                        { !isMobile ? <Comunicado firstID={firstID} isMobile={isMobile} /> : null }
                    </Route>
                    <Route path={  baseurl + '/:comunicadoId' }>
                        <Comunicado firstID={firstID} isMobile={isMobile} />
                    </Route>
                </Switch>
            </div>
        );
  
    }

}
  
export default withRouter( Comunicados );