import React, { Component } from 'react';
import axios from 'axios';
import { withRouter } from "react-router";
import { NavLink } from "react-router-dom";
import {Helmet} from 'react-helmet'
import PropTypes from 'prop-types';
import ReactHtmlParser from 'react-html-parser';

import { BlogBBPLink } from '../helpers/Common';
import Sharebar from '../blocks/Sharebar';
import Relateds from '../blocks/Relateds';
import TimeDeEspecialistas from '../blocks/TimeDeEspecialistas';

export class BlogBBPSingle extends Component {

    static propTypes = {
        match: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired
    };

    constructor( props ){

        super();

        this.state = {
            items: [],
            isLoaded: false
        }

    }
    
    getData( pars ){

        const 
            SERVER_URL = process.env.REACT_APP_SERVER_URL,
            { category, itemID } = pars,
            type = ( category === 'curadoria' ? 'curadoria' : 'blogbbp' );
        ;

        // Request post
        axios.get( SERVER_URL + 'wp-json/bbprev/v1/blogbbp-single/' + itemID + '?type=' + type )
            .then(res => this.setState({
                isLoaded: true,
                item: res.data
            }))
            .catch( err => console.log( err ) )
        ;
        
    }
    
    componentDidMount(){
        const { match } = this.props;
        this.getData( match.params );
    }
    
    UNSAFE_componentWillReceiveProps( nextProps ){
        const { match } = nextProps;
        this.getData( match.params );
    }
  
    render() {

        const 
            { history } = this.props,
            { item, isLoaded } = this.state
        ;
        if( !item || !isLoaded ) return null;
        
        // Trimed title
        const trimmed = item.post_title.substring( 0, 20 ).trim() + '…';

        // With media
        const withMedia = item.custom.photo || item.custom.video;

        // BASE url
        const share_url = process.env.REACT_APP_API_URL + BlogBBPLink( item );

        // Adjacents
        if( item.adjacents ){
            var 
                prev_url = item.adjacents.prev ? BlogBBPLink( item.adjacents.prev ) : false,
                next_url = item.adjacents.next ? BlogBBPLink( item.adjacents.next ) : false
            ;            
        }

        // Button color
        const btncolor = item.custom.btncolor === 'dark' ? '4' : '2';

        // Helmet meta
        var ogMeta = [
            {"property": "og:title", "content": item.post_title},
            {"property": "og:title", "content": item.post_title},
            {"property": "twitter:title", "content": window.location.href}
        ];
        if( item.custom.photo ){
            ogMeta.push({"property": "og:image", "content": item.custom.photo});
            ogMeta.push({"property": "twitter:card", "content": "summary_large_image"});
            ogMeta.push({"property": "twitter:image", "content": item.custom.photo});
        }

        // Renderize
        return(
            <React.Fragment>

                <Helmet meta={ogMeta}>
                    <title>{ item.post_title  + ' | BB Previdência' }</title>
                </Helmet>
                
                <article className={ 'single-post' + ( withMedia ? ' wiht-media' : '' ) }>
                    
                    <div>
                    
                        <button className={'styled size--2 color--'+ btncolor +' back'} onClick={history.goBack} title="Voltar">
                            <svg xmlns="http://www.w3.org/2000/svg" width="11.995" height="12.01" viewBox="0 0 11.995 12.01"><path id="Arrow" d="M17,11H9.41l3.3-3.29a1,1,0,1,0-1.42-1.42l-5,5a1.034,1.034,0,0,0,0,1.42l5,5a1,1,0,1,0,1.42-1.42L9.41,13H17a1,1,0,0,0,0-2Z" transform="translate(-6.005 -5.996)" fill="#0059a5"/></svg>
                            <span>Voltar</span>
                        </button>

                        {withMedia && <div className="media-wrapper">
                            { item.custom.video && 
                            <div className="video-wrapper">
                                <iframe width="560" height="315" src={'https://www.youtube.com/embed/'+item.custom.video} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe> 
                            </div>}
                            { ( !item.custom.video && item.custom.photo ) && 
                            <img src={item.custom.photo} alt={item.post_title} /> }
                        </div>}

                        <div className="content">
                            <header>
                                <h2><mark>{item.post_title}</mark></h2>
                                <nav className="crumbs">
                                    <NavLink to="/blogbbp" title="Blog BBP">
                                        Blog BBP
                                    </NavLink>
                                    <span>&gt;</span>
                                    <NavLink to={"/blogbbp/"+item.custom.editoria.slug} title={item.custom.editoria.name}>
                                        {item.custom.editoria.name}
                                    </NavLink>
                                    <span>&gt;</span>
                                    <em>{trimmed}</em>
                                </nav>
                                <em className="date">{item.custom.date_complete}</em>
                                {item.custom.tags && 
                                <div className="tags">
                                    <em>Tags:</em>
                                    <ul>{item.custom.tags.map( tag =>
                                        <li key={'tag-'+tag.term_id}>{tag.name}</li>
                                    )}
                                    </ul>
                                </div>}
                            </header>
                            <div className="texts">
                                {ReactHtmlParser(item.post_content)}
                            </div>
                            <footer>
                                <button className="styled size--2 color--3 back" onClick={history.goBack} title="Voltar">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="11.995" height="12.01" viewBox="0 0 11.995 12.01"><path id="Arrow" d="M17,11H9.41l3.3-3.29a1,1,0,1,0-1.42-1.42l-5,5a1.034,1.034,0,0,0,0,1.42l5,5a1,1,0,1,0,1.42-1.42L9.41,13H17a1,1,0,0,0,0-2Z" transform="translate(-6.005 -5.996)" fill="#0059a5"/></svg>
                                    <span>Voltar</span>
                                </button>
                                <Sharebar url={share_url} title={item.post_title} />
                            </footer>
                        </div>
                    </div>

                    {item.adjacents && 
                    <nav className="adjacents">
                        <span>
                            {prev_url &&
                            <NavLink to={prev_url} className="previous" title={item.adjacents.prev.title}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="24" viewBox="0 0 14 24"><path id="Left" d="M8.745,18.733l10.364,10.03a1.776,1.776,0,0,0,2.545,0h0a1.714,1.714,0,0,0,0-2.507L12.745,17.3l8.909-8.955a1.714,1.714,0,0,0,0-2.507,1.776,1.776,0,0,0-2.545,0L8.745,15.867a2.155,2.155,0,0,0,0,2.866Z" transform="translate(-8.2 -5.3)" fill="#0059a5"/></svg>
                                <strong>{item.adjacents.prev.title}</strong>
                            </NavLink>}
                        </span>
                        <span>
                            {next_url &&
                            <NavLink to={next_url} className="next" title={item.adjacents.next.title}>
                                <strong>{item.adjacents.next.title}</strong>
                                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="24" viewBox="0 0 14 24"><path id="Left" d="M8.745,18.733l10.364,10.03a1.776,1.776,0,0,0,2.545,0h0a1.714,1.714,0,0,0,0-2.507L12.745,17.3l8.909-8.955a1.714,1.714,0,0,0,0-2.507,1.776,1.776,0,0,0-2.545,0L8.745,15.867a2.155,2.155,0,0,0,0,2.866Z" transform="translate(-8.2 -5.3)" fill="#0059a5"/></svg>
                            </NavLink>}
                        </span>
                    </nav>}

                </article>
                
                {item.relateds && 
                <Relateds items={item.relateds} />}
                
                <TimeDeEspecialistas />
            
            </React.Fragment>
        
        );
  
    }

}
  
export default withRouter( BlogBBPSingle );