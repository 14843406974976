import React from 'react';
import { withRouter } from 'react-router';
import $ from 'jquery';

export class PikiLoader extends React.Component {

	constructor( props ){

		super( props );
		
        this.state = {
			$ : false,
			firstTime : true,
			maskOpened : false,
			indexes: [],
			zIndex: 2100,
			target: false,
			mask: false,
			position: 'fixed',
			toBehind: false

		}

	}
		  
    componentDidMount = () => {

		if( this.state.firstTime ){

			this.setState({
				$ : $( '.piki-loader' ).first(),
				firstTime : false
			});
	
		}
	
	}

    componentDidUpdate = ( prevProps ) => {

        let { location: { pathname } } = this.props;
		if ( prevProps.location.pathname === pathname ) return;
		
		this.open();

    }

    routeChanged = () => {
		this.close();
    }
	
	open = ( options ) => {
		this.state.$.fadeIn( 300 );
	}

	close = ( target ) => {
		this.state.$.fadeOut( 200 );
	}

	render() {
		return (
			<div id="piki-loader" className="piki-loader"></div>
		)
	}

}

export default withRouter( PikiLoader );
