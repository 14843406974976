import React, { Component } from 'react';
import { withRouter } from "react-router";
import { NavLink } from "react-router-dom";
import { Icons } from '../helpers/Icons';

export class Breadcrumb extends Component {

    constructor( props ){

        super();

        this.state = {
            isMobile: false
        };
        this.timeResize = false;

    }
    
    componentDidMount(){
        this.checkSize();
        window.addEventListener( 
            'resize', 
            () => {
                if(  this.timeResize ) clearTimeout( this.timeResize );
                this.timeResize = this.checkSize();
            }, 
            true 
        );
    }

    componentWillUnmount(){
        window.removeEventListener( 'resize', this.checkSize );
    }

    checkSize = () => {
        const width = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
        this.setState({
            isMobile: width <= 960
        })
    }

    goBack = () => {
        this.props.history.goBack()
    }

    render(){

        const { data } = this.props;

        return (
        <div className="breadcrumb">
            <nav>
                <NavLink to="/">Home</NavLink>
                <Icons tag="crumbdiv" />
                {
                    data.path
                    ? <NavLink to={data.path}>{data.label}</NavLink>
                    : <strong>{data.label}</strong>
                }
            </nav>
            {
            this.state.isMobile 
            ? <button onClick={this.goBack}>
                <Icons tag="arrowright" />
                Voltar
            </button>
            : ''
            }
        </div>  
        );      

    }

}

export default withRouter( Breadcrumb )