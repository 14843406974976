import React, { Component } from 'react';
import PerguntasFrequentes from './PerguntasFrequentes.js';

export default class Duvidas extends Component {
    
    render(){

        const { siteData } = this.props;

        return (
            <section id="duvidas" className="duvidas">
                <div className="wcf">
                    <header>
                        <h3>{siteData.duvidas.title}</h3>
                        <em>{siteData.duvidas.excerpt}</em>
                    </header>
                    <PerguntasFrequentes siteData={siteData} />
                </div>
            </section>
        )

    }

}