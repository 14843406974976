import React from 'react';
import Icons from '../helpers/Icons';

export class ListFiles extends React.Component {

    render(){

        const { data } = this.props;
        if( !data ) return null;

        return (
            <ul className="lista-botoes full">
                {data.map(file=>
                    <li key={file.id}>
                        <a href={file.url} title="Baixar arquivo" target="_blank" rel="noreferrer">
                            <Icons tag={file.extension} />
                            {file.title}
                        </a>
                    </li>
                )}
            </ul>
        );

    }

}

export default ListFiles;