import $ from 'jquery';

export default function scrollTo( selector, offset ){

    const
        $window = $( window ),
        $header = $( '#masthead' )
    ;

    var $target = typeof( selector ) === 'object' ? selector : $( selector );            
    if( !$target.length ) return;

    let newY;
    if( $target.attr( 'id' ) === 'inicio' ){
        newY = 0;
    }
    else {
        newY = $target.offset().top;
    }

    var actualY = $window.scrollTop();
    var distance = actualY - newY;
    if( distance < 0 ) distance *= -1;

    var time = ( 600 - ( distance * 0.1 ) );
    if( time < 600 ) time = 600;

    if( offset === undefined ){
    
        offset = $target.data( 'offset' );

        var _offset = false;
        if( offset ){

            try {

                let 
                    _json = JSON.parse( offset.split( "'" ).join( '"' ) ),
                    _vWidth = this.vWidth()
                ;

                $.each( _json.breaks, function( _break ){
                    if( _vWidth <= _break ){
                        _offset = parseInt( this );
                        return false;
                    }
                });

                offset = ( !_offset ) ? parseInt( _json.initial ) : _offset;

            }
            catch( e ){

                _offset = parseInt( offset );
                offset = _offset !== 0 ? _offset : 0;
            
            }

        }
        else {
            offset = 0;
        }

    }

    if( $header ){
        offset += $header.outerHeight();
    }

    $( 'html, body' ).animate({
        scrollTop: ( newY - offset )
    }, time );      

}