import React, { Component } from 'react';
import axios from 'axios';
import {Helmet} from 'react-helmet'

import Breadcrumb from '../blocks/Breadcrumb';
import { Icons } from "../helpers/Icons";

export class Documentos extends Component {

    state = {
        items: [],
        isLoaded: false
    }

    componentDidMount () {

        const SERVER_URL = process.env.REACT_APP_SERVER_URL;

        axios
            .get( SERVER_URL + 'wp-json/wp/v2/documentos?siteID=' + window.siteID + '&_fields=id,title,file' )
            .then(( res ) => {
                this.setState({
                    items: res.data,
                    isLoaded: true
                })
            })
            .catch(
                err => console.log(err)
            )
        ;
  
    }

    render() {

        const 
            { items, isLoaded } = this.state,
            toCrumb = {
                path: '/#central-de-informacoes',
                label: 'Central de informações'
            }
        ;
        if( !isLoaded ) return null;

        const _title = 'Documentos | BB Previdência';

        return (
            <div className="page documentos">
                <Helmet>
                    <title>{_title}</title>
                    <meta property="og:title" content={_title} />
                    <meta name="twitter:title" content={_title} />
                </Helmet>

                <div className="wcf">
                    <Breadcrumb data={toCrumb} />
                    <h2 className="page-title">Documentos</h2>
                    <ul className="lista-botoes full">
                        {items.map( item =>
                            <li key={item.id}>
                                <a href={item.file.url} className="button" target="_blank" rel="noreferrer">
                                    <Icons tag={item.file.extension} />
                                    <h3>{item.title.rendered}</h3>
                                </a>
                            </li>
                        )}
                    </ul>
                </div>
            </div>
        );
  
    }

} 
export default Documentos;