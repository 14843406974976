import React, { Component } from 'react';
import { NavLink } from "react-router-dom";

export class ClubeVantagensPlano extends Component {

    constructor( props ){

        super( props )

        this.state = {
            isLoaded: false,
            isMobile: false,
            items: []
        }
    
    }

    render(){

        const { parceiros } = this.props;
        if( parceiros === undefined ) return null;
        
        return(
            <section className="clube-de-vantagens plano">
                <div className="wcf">
                    <h3>{parceiros.title}</h3>
                    <em>{parceiros.excerpt}</em>
                    {
                        parceiros.items
                        ? <ul className="lista-logos">
                            { parceiros.items.map( logo => 
                            <li key={logo.key}>
                                { 
                                logo.url 
                                ? <a href={logo.logo} target="_blank" rel="noreferrer"><img src={logo.logo} alt={logo.name} /></a> 
                                : <img src={logo.logo} alt={logo.name} /> }
                            </li> 
                            )}
                        </ul>
                        : null
                    }
                    <NavLink to="/clube-de-beneficios" title="Clique para conhecer o clube de Benefícios" className="button default color--5"><span>Conhecer clube de Benefícios</span></NavLink>
                </div>
            </section>
        )

    }

}

export default ClubeVantagensPlano;