import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom'
import App from './App';
import reportWebVitals from './scripts/helpers/reportWebVitals';
import WebFont from 'webfontloader';
import './App.scss';
WebFont.load({
  	google: {
    	families: [ 'Lato:300,400,700,900', 'Noto Sans JP:400,700' ]
  	}
});

ReactDOM.render(
  	<React.StrictMode>
    	<App />
  	</React.StrictMode>,
  	document.getElementById( 'root' )
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
