import React, { Component } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import Moment from 'moment';
import { withRouter } from "react-router";
import { NavLink } from "react-router-dom";
import Slider from "react-slick";

import Icons from '../helpers/Icons';

export class Comunicados extends Component {

    static propTypes = {
        match: PropTypes.object.isRequired,
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired
    };

    constructor( props ){

        super( props )

        this.state = {
            isLoaded: false,
            isMobile: false,
            items: []
        }
    
    }
  
    componentDidMount () {

        const SERVER_URL = process.env.REACT_APP_SERVER_URL;

        axios
            .get( SERVER_URL + 'wp-json/wp/v2/comunicados?siteID=' + window.siteID + '&_fields=id,slug,title,date' )
            .then( res => this.setState({
                  isLoaded: true,
                  items: res.data
            }))
            .catch( err => console.log(err) )
        ;

    }

    render() {

        const { items, isLoaded } = this.state;
        if( !isLoaded || !items.length ) return null;

        const total = items.length;
        const slideCount = total < 3 ? total : 3;
        var slickSettings;
        
        if(  slideCount === 1 ){
            slickSettings = {};
        }
        else if( slideCount === 2 ){
            slickSettings = {
                slidesToShow: 2,
                slidesToScroll: 2,
                arrows: true,
                dots: true,
                responsive: [
                    {
                        breakpoint: 840,
                        settings: {
                            slidesToShow: 1,
                            slidesToScroll: 1,
                            arrows: true,
                            dots: true
                        }
                    }
                ]
            };
        }
        else {
           slickSettings = {
                slidesToShow: 3,
                slidesToScroll: 3,
                arrows: true,
                dots: true,
                responsive: [
                    {
                        breakpoint: 1080,
                        settings: {
                            slidesToShow: 2,
                            slidesToScroll: 2,
                            arrows: true,
                            dots: true
                        }
                    },
                    {
                        breakpoint: 820,
                        settings: {
                            slidesToShow: 1,
                            slidesToScroll: 1,
                            arrows: true,
                            dots: true,
                            addaptiveHeight: true
                        }
                    }
                ]
            };
        }

        return (
            <section id="comunicados" className="comunicados">
                <div className="wcf">
                    <h3>Comunicados</h3>
                    <Slider {...slickSettings} className="list-items">
                        {items.map(item =>
                            <div key={item.id} data-id={item.id} className="list-item">
                                <NavLink to={`/comunicados/${item.slug}`}>
                                    <h4>{item.title.rendered}</h4>
                                    <em>{Moment(item.date).format('DD/MM/YYYY')}</em>
                                </NavLink>
                            </div>
                        )}
                    </Slider>
                    <NavLink to="/comunicados">
                        Veja todos os comunicados
                        <Icons tag="arrowright" />
                    </NavLink>
                </div>
            </section>
        )
   
    }

}

export default withRouter( Comunicados );