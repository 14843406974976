import React from 'react';
import $ from 'jquery';

import Accordion from '../helpers/Accordion';
import Subpage from '../blocks/Subpage';

export class Subpages extends React.Component {

    constructor( props ){
    
        super();
    
        this.state = {
            isLoaded: false
        };

        this.wrapper = React.createRef();
        this.timeout = false;
    
    }

    componentDidMount(){

        this.setState({
            isLoaded: true,
        }, () => {

            if( this.timeout ) clearTimeout( this.timeout );
            this.timeout = setTimeout( () => {
                this.checkFiles();
            }, 50 );
        
        });

    }

    componentWillReceiveProps( nextProps ){

        if( this.timeout ) clearTimeout( this.timeout );
        this.timeout = setTimeout( () => {
            this.checkFiles();
        }, 50 );
    
    }
  
    checkFiles = () => {

        const $fwrappers = $( 'div.arquivos', this.wrapper.current );
        if( $fwrappers.length ){
            $fwrappers.each(function(){

                this.$ = this.$ || $( this );
                const $files = this.$.parents( '.content' ).first().children( '.list-files' );
                if( $files.length ){
                    $files.appendTo( this.$ );
                }

            });
        }

    }

    renderAccordion = () => {
        
        const { data } = this.props;

        return (
            <div className="subpages">
                {
                    data.title !== ''
                    ? <h3>{data.title}</h3>
                    : null
                }
                <Accordion items={data.items} />
            </div>    
        )

    }

    renderExpandeds = () => {

        const { data } = this.props;

        return(
            <div ref={this.wrapper} className="subpages full">
                {data.items.map( page => 
                    <Subpage page={page} />
                )}
            </div>
        );

    }

    render(){

        const { data } = this.props;
        if( !data ) return null;

        if( data.expand_childs ){
            return this.renderExpandeds();
        }
        else {
            return this.renderAccordion();
        }        

    }

}

export default Subpages;
