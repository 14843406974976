import React, { Component } from 'react';
import Icons from '../helpers/Icons';

export class Previc extends Component {

    render(){

        const {data} = this.props;
        
        return(
            <section className="previc wcf">
                <div>
                    <picture>
                        <source media="(max-width: 540px)" srcSet={data.cover_mobile} />
                        <source media="(min-width: 541px)" srcSet={data.cover} />
                        <img src={data.cover} alt={data.title} />
                    </picture>
                    <div>
                        <h3>{data.title}</h3>
                        <em>{data.excerpt}</em>
                    </div>
                    <a href={data.url} title={data.title} className="arrow-right" target="_blank" rel="noreferrer">
                        Acesse o site
                        <Icons tag="arrowright" />
                    </a>
                </div>
            </section>
        )

    }

}

export default Previc;