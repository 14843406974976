import React, { Component } from 'react';
import $ from 'jquery';

export class Sharebar extends Component {

    constructor( props ){

        super();

        this.openSharebox = this.openSharebox.bind();

    }

    openSharebox(e){

        // Prevent default
        e.preventDefault();
        
        // Target
        e.target.$ = e.target.$ || $( e.target );
        if( !e.target.$.is( 'a' ) ){
            e.target.$ = e.target.$.parents( 'a' ).first();
        }

        // Open share modal
        window.open( 
            e.target.$.attr( 'href' ), 
            e.target.$.attr( 'title' ), 
            'width=500,height=500' 
        );

    }

    render(){

        const { url, title } = this.props;
        const
            url_twitter = 'http://twitter.com/share?url=' + url + '&text=' + title,
            url_facebook = 'https://www.facebook.com/sharer/sharer.php?u=' + url,
            url_linkedin = 'https://www.linkedin.com/sharing/share-offsite/?url=' + url,
            url_whatsapp = 'https://api.whatsapp.com/send?text=' + url
        ;

        return(
            <div className="sharebar">
                <em>Compartilhe:</em>
                <nav>
                    {/* <a href="" title="Copiar URL" className="copy">
                        <svg xmlns="http://www.w3.org/2000/svg" width="15.984" height="16" viewBox="0 0 15.984 16"><path id="Link" d="M8.468,14.525,7.043,15.91a1.993,1.993,0,0,1-2.819-2.819L7.88,9.427a1.981,1.981,0,0,1,2.73-.072l.1.081a.806.806,0,0,0,1.128-1.152,2.215,2.215,0,0,0-.145-.169,3.592,3.592,0,0,0-4.9.177l-3.7,3.665a3.608,3.608,0,0,0,5.1,5.1l1.393-1.361a.805.805,0,0,0-1.1-1.168Zm8.61-11.469a3.616,3.616,0,0,0-5.1,0L10.586,4.417a.805.805,0,0,0,1.1,1.168L13.083,4.2A1.993,1.993,0,0,1,15.9,7.019l-3.656,3.665a1.981,1.981,0,0,1-2.73.072l-.1-.081a.806.806,0,1,0-1.128,1.152A2.215,2.215,0,0,0,8.476,12a3.6,3.6,0,0,0,4.9-.177l3.665-3.665A3.616,3.616,0,0,0,17.078,3.056Z" transform="translate(-2.129 -2.005)" fill="#0059a5"/></svg>
                    </a> */}
                    <a href={url_facebook} onClick={this.openSharebox} title="Facebook" className="facebook" target="_blank" rel="noreferrer">
                        <svg xmlns="http://www.w3.org/2000/svg" width="8.312" height="16" viewBox="0 0 8.312 16"><path id="Facebook" d="M13.418,4.656h1.5V2.112A20.888,20.888,0,0,0,12.73,2,3.431,3.431,0,0,0,9.066,5.76v2.1H6.61V10.7H9.066V18H12.01V10.7h2.448l.368-2.848H12.01V6.04C12.01,5.2,12.234,4.656,13.418,4.656Z" transform="translate(-6.61 -2)" fill="#0059a5"/></svg>
                    </a>
                    <a href={url_linkedin} onClick={this.openSharebox} title="LinkedIn" className="linkedin">
                        <svg xmlns="http://www.w3.org/2000/svg" width="15.904" height="16" viewBox="0 0 15.904 16"><path id="Linkedin" d="M16.788,2H3.236A1.16,1.16,0,0,0,2.06,3.144V16.856A1.16,1.16,0,0,0,3.236,18H16.788a1.16,1.16,0,0,0,1.176-1.144V3.144A1.16,1.16,0,0,0,16.788,2Zm-9.9,13.392h-2.4v-7.2h2.4Zm-1.2-8.208h0a1.248,1.248,0,1,1,0-2.5,1.256,1.256,0,1,1,0,2.5Zm9.856,8.208h-2.4V11.528c0-.968-.344-1.6-1.216-1.6a1.32,1.32,0,0,0-1.232.872,1.6,1.6,0,0,0-.08.584v4h-2.4v-7.2h2.4V9.2A2.4,2.4,0,0,1,12.78,8c1.6,0,2.76,1.032,2.76,3.248Z" transform="translate(-2.06 -2)" fill="#0059a5"/></svg>
                    </a>
                    <a href={url_whatsapp} title="Whatsapp" className="whatsaspp" target="_blank" rel="noreferrer">
                        <svg xmlns="http://www.w3.org/2000/svg" width="15.905" height="16" viewBox="0 0 15.905 16"><path id="WhatsApp" d="M13.665,11.583c-.16-.08-1.2-.559-1.358-.639a.376.376,0,0,0-.479.08c-.16.16-.479.639-.639.8-.08.16-.24.16-.4.08a6.532,6.532,0,0,1-1.6-.959A9.2,9.2,0,0,1,8.072,9.585a.351.351,0,0,1,.08-.4c.08-.08.16-.24.32-.32a.606.606,0,0,0,.16-.32.243.243,0,0,0,0-.32,14.2,14.2,0,0,1-.639-1.438c-.08-.559-.24-.559-.4-.559h-.4a.911.911,0,0,0-.479.24A2.3,2.3,0,0,0,6,8.147a4.186,4.186,0,0,0,.8,2.077,8.519,8.519,0,0,0,3.356,2.956,6.262,6.262,0,0,0,1.119.4,2.282,2.282,0,0,0,1.278.08,2.07,2.07,0,0,0,1.358-.959,1.336,1.336,0,0,0,.08-.959l-.32-.16m2-7.271A7.964,7.964,0,0,0,3.119,13.9L2,17.975l4.235-1.119a7.953,7.953,0,0,0,3.755.959h0a7.877,7.877,0,0,0,7.91-7.91,7.782,7.782,0,0,0-2.237-5.593M13.506,15.5A6.706,6.706,0,0,1,9.99,16.537h0a7.007,7.007,0,0,1-3.356-.879l-.24-.16-2.477.639.639-2.4L4.4,13.5A6.709,6.709,0,0,1,6.554,4.312a6.5,6.5,0,0,1,9.029,2.077A6.593,6.593,0,0,1,13.506,15.5" transform="translate(-2 -1.975)" fill="#0059a5"/></svg>
                    </a>
                    <a href={url_twitter} onClick={this.openSharebox} title="Twitter" className="twitter" target="_blank" rel="noreferrer">
                        <svg xmlns="http://www.w3.org/2000/svg" width="19.654" height="16" viewBox="0 0 19.654 16"><path id="Twitter" d="M21.654,5.766a8.343,8.343,0,0,1-2.319.629,4.059,4.059,0,0,0,1.779-2.231,8.068,8.068,0,0,1-2.565.983A4.029,4.029,0,0,0,11.67,8.823a11.439,11.439,0,0,1-8.3-4.216,4.088,4.088,0,0,0-.54,2.034A4.019,4.019,0,0,0,4.614,9.992a3.98,3.98,0,0,1-1.828-.5V9.54a4.029,4.029,0,0,0,3.243,3.931,3.862,3.862,0,0,1-1.081.167,4.815,4.815,0,0,1-.757-.069A4.039,4.039,0,0,0,7.955,16.36a8.078,8.078,0,0,1-4.973,1.73A7.793,7.793,0,0,1,2,18.031a11.37,11.37,0,0,0,6.181,1.818A11.39,11.39,0,0,0,19.689,8.37V7.85a8.284,8.284,0,0,0,1.965-2.083Z" transform="translate(-2 -3.849)" fill="#0059a5"/></svg>
                    </a>
                </nav>
            </div>
        );

    }

}

export default Sharebar;