import React from 'react';
import { NavLink } from "react-router-dom";
import grafismo from '../../assets/images/not-found.png'; 

export default class NotFound extends React.Component {

    render(){

        const { siteID } = this.props;

        return(
            <div className="page not-found wcf">
                <img src={grafismo} alt="Página não encontrada" />
                <h3>Ops! A página não foi encontrada</h3>
                <em>Código de erro: 404</em>
                <p>O endereço pode estar errado ou a página não existe mais.</p>
                { siteID ? <NavLink to="/">Página Inicial</NavLink> : null }
            </div>
        )
    
    }

}
