import React from 'react';
import { NavLink } from "react-router-dom";
import { instanceOf } from 'prop-types';
import { withCookies, Cookies } from 'react-cookie';

export class Disclaimer extends React.Component {
    
    static propTypes = {
        cookies: instanceOf( Cookies ).isRequired
    };

    constructor( props ){
        
        super( props );
     
        const { cookies } = props;
        this.state = {
            accept_cookies: cookies.get( 'accept_cookies' ) || false
        };
    
    }

    accept = () => {

        const { cookies } = this.props;
        cookies.set( 'accept_cookies', 'true', { path: '/' } );
        this.setState({ accept_cookies: 'true' });

    }

    render(){

        const { cookies } = this.props.data;
        if( !cookies ) return null;

        const { accept_cookies } = this.state;
        if( accept_cookies === 'true' ) return null;
        
        return(
            <div className="cookies">
                <div className="wcf">
                    <em>{cookies.disclaimer}</em>
                    <NavLink to="/politica-de-privacidade">Saiba mais</NavLink>
                    <menu>
                        <li><button className="accept" onClick={this.accept}>Aceitar</button></li>
                        <li><button className="denied" onClick={this.accept}>Recusar</button></li>
                    </menu>
                </div>
            </div>
        )
    
    }

}

export default withCookies( Disclaimer )
