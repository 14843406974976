import React, { Component } from 'react'

import MetaTags from '../helpers/MetaTags';

import Destaque from '../blocks/Destaque';
import ClubeVantagens from '../blocks/ClubeVantagens';
import Planos from '../blocks/Planos';
//import Noticias from '../blocks/Noticias';
import Comunicados from '../blocks/Comunicados';
//import EducacaoFinanceira from '../blocks/EducacaoFinanceira';
import BlogBBP from '../blocks/BlogBBP';
import CentralInformacoes from '../blocks/CentralInformacoes';
import Previc from '../blocks/Previc';
import Duvidas from '../blocks/Duvidas';

export class Home extends Component {

    render() {

        const { siteData } = this.props;        
        const { 
            why, 
            parceiros, 
            //educativos,
            //blogbbp,
            previc 
        } = siteData;

        const blogData = siteData?.pensefuturo !== undefined ? siteData.pensefuturo : siteData.blogbbp;
                
        return (
            <div className="home">
                <MetaTags siteData={siteData} />
                <Destaque />
                <ClubeVantagens why={why} parceiros={parceiros} />
                <Planos />
                {/* <Noticias siteData={siteData} /> */}
                <Comunicados />
                <BlogBBP data={blogData} />
                {/* <EducacaoFinanceira siteData={educativos} /> */}
                <CentralInformacoes siteData={siteData} />
                <Previc data={previc} />
                <Duvidas siteData={siteData} />
            </div>
        );

    }

}

export default Home