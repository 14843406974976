import React, { Component } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { API } from '../helpers/API';
import { LoaderMask } from '../helpers/LoaderMask';



export default class StatusConvenio extends Component {

    constructor( props ){

        super( props );

        this.state = {
            keyword : '',
            selected : null,
            result : false,
            lastID : false,
            loading : false,
            options : false,
            mask: new LoaderMask()
        };

        this.setInputValue = this.setInputValue.bind( this );
        this.bindSelect = this.bindSelect.bind( this );
        this.bindSubmit = this.bindSubmit.bind( this );

    }

    async componentDidMount(){

        const 
            { siteData } = this.props,
            options = await API.Licenciamento.autocomplete( siteData.key )
        ;
        
        this.setState({ options });

    }

    bindSelect( value ){

        var newState = {
            selected : value === null ? null : value.id
        };
        if( value === null ){
            newState.result = false;
            newState.lastID = false;
        }

        this.setState( newState );
    }

    setInputValue( value ){
        this.setState({ keyword : value });
    }

    async bindSubmit(){

        const { selected, lastID } = this.state;

        if( selected !== null && lastID !== parseInt( selected ) ){

            this.state.mask.open();

            const result = await API.Licenciamento.get( selected );
            if( result ){
                this.setState({ 
                    result: result,
                    lastID : result.id 
                });
            }

            this.state.mask.close();
        
        }

    }

    render(){

        const 
            //{ siteData } = this.props,
            { options, keyword, selected, result, loading } = this.state
        ;

        if( !options || !options.length ) return null;

        return(
            <section id="status-convenio">
                <h2>Informações sobre plano ou ente federativo</h2>
                <div className="content">
                    <div className="label-form">
                        <h3>Acesse informações específicas do <br />seu plano ou ente federativo.</h3>
                        <div className="form">
                            <Autocomplete
                                disablePortal
                                id="combo-box-demo"
                                options={options}
                                onChange={( any, newValue ) => {
                                    this.bindSelect( newValue );
                                }}                                
                                inputValue={keyword}
                                onInputChange={( event, newInputValue ) => {
                                    this.setInputValue( newInputValue );
                                }}                                
                                sx={{ width: '100%' }}
                                renderInput={(params) => <TextField {...params} label="Nome do Plano ou Ente Federativo" />}
                                noOptionsText="Nenhum Plano ou Ente Federativo encontrado"
                            />
                            <button type="button" className="do-search" disabled={ loading || selected === null } onClick={this.bindSubmit}>Buscar</button>
                        </div>
                    </div>
                    { result &&
                    <div className="result">
                        <h4>{result.title.rendered}</h4>
                        <div className="list-wrapper">
                            <ul>
                                <li>
                                    <em>UF</em>
                                    <strong>{result.custom.estado}</strong>
                                </li>
                                <li>
                                    <em>Tipo de Licenciamento</em>
                                    <strong>{result.custom.tipo}</strong>
                                </li>
                                <li>
                                    <em>Fase do Licenciamento</em>
                                    <strong>{result.custom.fase}</strong>
                                </li>
                                {result.custom.data_protocolo && 
                                <li>
                                    <em>Data de Protocolo (Previc)</em>
                                    <strong>{result.custom.data_protocolo}</strong>
                                </li>}
                                {result.custom.data_aprovacao && 
                                <li>
                                    <em>Data da Aprovação (Previc)</em>
                                    <strong>{result.custom.data_aprovacao}</strong>
                                </li>}
                                {result.custom.portaria &&
                                <li>
                                    <em>Portaria de Aprovação</em>
                                    <strong>{result.custom.portaria}</strong>
                                </li>}
                                {result.custom.implantacao &&
                                <li>
                                    <em>Situação da Implantação</em>
                                    <strong>{result.custom.implantacao}</strong>
                                </li>}
                                {result.custom.observacao &&
                                <li>
                                    <em>Observações</em>
                                    <strong>{result.custom.observacao}</strong>
                                </li>}
                            </ul>
                        </div>
                    </div>}
                </div>
            </section>
        )

    }

}