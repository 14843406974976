import React, { Component } from 'react';
import { Splide, SplideSlide } from '@splidejs/react-splide';

export class StickedPlans extends Component {

    extractSickies = () => {

        const planos = window.planos;

        let stickies = [];
        planos.forEach(function( plano ){
            if( plano.sticky !== null && plano.sticky > 0 ){
                stickies.push( plano )
            }
        });

        return ( !stickies.length ? false : stickies )
    
    }

    render(){

        const 
            stickies = this.extractSickies(),
            API_URL = process.env.REACT_APP_API_URL
        ;

        if( !stickies ) return null

        let sliderSettings = {
            autoWidth : true,
            pagination : false,
            gap : "40px"
        }

        // Mobile
        if( window.innerWidth <= 768 ){
            sliderSettings.autoWidth = false
        }
        // Desktop
        else {
            sliderSettings.autoWidth = true
        }

        return (
        <nav className="sticked-plans">
            <div style={{position:'relative',display:'block',width:'100%'}}>
                <Splide data-splide={JSON.stringify(sliderSettings)}>
                    {stickies.map( plano => {
                        const label = ( plano.sticky_label && plano.sticky_label !== '' ? plano.sticky_label : plano.name )
                        return (
                        <SplideSlide key={'slide-'+plano.id}>
                            <a href={ API_URL + '/' + plano.sitekey} key={plano.id}>{label}</a>
                        </SplideSlide>)
                    })}
                </Splide>
            </div>
        </nav>)

    }

}

export default StickedPlans