import React, { Component } from 'react'
import Icons from '../helpers/Icons';

export default class Networks extends Component  {

    render(){

        const items = this.props.data;
        if( !items ) return null;
        
        return (

            <nav className="Sherer">
                {items.map( ( network ) =>
                    <a key={network.key} href={network.url} title="{network.label}" target="_blank" rel="noreferrer">
                        <Icons tag={network.key} />
                    </a>
                )}
            </nav>
        
        )

    }

}