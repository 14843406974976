import React, { Component } from 'react'
import { withRouter } from 'react-router';
import ReactHtmlParser from 'react-html-parser';

import { API } from '../helpers/API';
import MetaTags from '../helpers/MetaTags';
import Breadcrumb from '../blocks/Breadcrumb';
import ListFiles from '../blocks/ListFiles';
import Subpages from '../blocks/Subpages';
import NotFound from './NotFound';

export class Page extends Component {

    constructor( props ){

        super( props );

        this.state = {
            isLoaded: false,
            data: false
        }
    
    }

    componentDidMount(){
        const { pathname } = this.props.location;
        this.getData( pathname );
    }

    componentWillReceiveProps( nextProps ){

        const oldPath = this.props.location.pathname;
        const newPath = nextProps.location.pathname;
        if( newPath === oldPath ) return;
        
        this.getData( newPath );
    
    }
        
    async getData( slug ){

        if( slug.indexOf( '/' ) === 0 ){
            slug = slug.substr( 1 );
        }

        const data = await API.Common.getPageData( slug );
        this.setState({
            data: data,
            isLoaded: true
        })
    
    }

    render() {

        const { isLoaded, data } = this.state;
        if( !isLoaded ) return null;

        const toCrumb = {
            path: false,
            label: 'Institucional'
        };

        if( data === '' ){
            return <NotFound />
        }
        else {
            return (
                <div className="page blue single">
                    <MetaTags siteData={data} />
                    <div className="wcf">
                        <Breadcrumb data={toCrumb} />
                        <article>
                            <h2 className="page-title">{data.post_title}</h2>
                            {
                                data.subtitle !== ''
                                ? <em>{data.subtitle}</em>
                                : null
                            }
                            <div className="content">
                                {ReactHtmlParser(data.content)}
                            </div>
                            <ListFiles data={data.files} />
                            <Subpages data={data.subpages} />
                        </article>
                    </div>
                </div>
            );
        }


    }

}

export default withRouter( Page )