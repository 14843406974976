import axios from 'axios';

var API = {

    URL : process.env.REACT_APP_API_URL + '/app',

    getPlans : function(){
        const API_URL = process.env.REACT_APP_API_URL;
        const response = axios.get( API_URL + '/data/planos.json' );
        return response.data;
    },

    Simulador : {

        GetParameters : async function( id_plano ){
            const response = await API.Post( 
                '/simulador', 
                { 
                    'id_plano': '63'
                } 
            );
            return response.data;
        },

        getSimulacao : async function( data, defaults ){
            const response = await API.Post(
                '/simular', 
                data
            );
            return response.data;        
        },

        sendToEmail : async function( data ){
            const response = await API.Post(
                '/envia-simulacao', 
                data
            );
            return response.data;        
        },

    },
    
    Licenciamento : {

        autocomplete : async function( sitekey ){

            const SERVER_URL = process.env.REACT_APP_API_URL;

            try {

                const response = await axios.get( SERVER_URL + '/data/'+ sitekey +'/licenciamentos.json' );
                if( response.status === 200 ){
                    return typeof( response.data ) === 'object' ? response.data : false;
                }
                else {
                    return false;
                }

            } 
            catch( err ) {
                return false;
            }

        },

        get : async function( ID ){

            const endpoint = process.env.REACT_APP_SERVER_URL + 'wp-json/wp/v2/licenciamentos/' + ID;
            
            try {
                const response = await axios.get( endpoint );
                if( response.status === 200 ){
                    return response.data;
                }
                else {
                    return false;
                }
            } 
            catch( err ) {
                return false;
            }
            
        }

    },

    OTP : {

        sendByEmail : async function( data ){
            const response = await API.Post(
                '/envia-codigo-email', 
                data
            );
            return response.data;
        },

        validaCodigo : async function( data ){
            const response = await API.Post(
                '/valida-codigo', 
                data
            );
            return response.data;
        },

        Resend : async function( data ){
            const response = await API.Post(
                '/reenviar-codigo', 
                data
            );
            return response.data;
        }

    },

    Jornada : {

        Get : async function( CPF ){
            const response = await API.Post(
                '/recupera-jornada', 
                { cpf: CPF }
            );
            if( response.data.status === 'success' ){
                return response.data.jornada;
            }
            else {
                return false;
            }
        },

        SelecionaTipo : async function( data ){
            const response = await API.Post(
                '/seleciona-tipo-adesao',
                data
            );
            return response.data;
        },

        Retomar : async function( email ){
            const response = await API.Post(
                '/retomar-adesao', 
                { email: email }
            );
            return response.data;
        },

        SendStep : async function( step, data ){
            const response = await API.Post(
                '/envia-passo-' + step, 
                data
            );
            return response.data;
        },

        DependenteSave : async function( data ){
            const response = await API.Post(
                '/salvar-dependente', 
                data
            );
            return response.data;
        },

        DependenteDelete : async function( data ){
            const response = await API.Post(
                '/deleta-dependente', 
                data
            );
            return response.data;
        },

        dependentesPercent : async function( data ){
            const response = await API.Post(
                '/envia-dependentes-porcentagem', 
                data
            );
            return response.data;
        },

        enviaArquivo : async function( data ){
            const response = await API.Post(
                '/envia-arquivo', 
                data
            );
            return response.data;
        },

        deletaArquivo : async function( data ){            
            const response = await API.Post(
                '/deleta-arquivo', 
                data
            );
            return response.data;
        }

    },

    Participante : {

        Validar : async function( cpf_partipante, cpf_jornada ){
            const response = await API.Post(
                '/validar-participante', 
                { 
                    cpf: cpf_partipante,
                    cpf_jornada: cpf_jornada
                }
            );
            return response.data;
        },

        Get : async function( cpf ){
            const response = await API.Post(
                '/recuperar-participante', 
                { 
                    cpf: cpf,
                }
            );
            return response.data;
        },

        Login : async function( data ){
            const response = await API.Post(
                '/login', 
                data
            );
            return response.data;
        },
    
    },

    Adesao : {

        EnviaCadastro : async function( data ){
            const response = await API.Post(
                '/pre-cadastro', 
                data
            );
            return response.data;
        },

        Consulta : async function( cpf ){
            const response = await API.Post(
                '/consulta-adesao', 
                { cpf: cpf }
            );
            return response.data;
        }

    },

    Common : {

        getPageData : async function( slug ){
            
            const endpoint = process.env.REACT_APP_SERVER_URL + 'wp-json/wp/v2/pages/' + slug;

            try {
                const response = await axios({
                    method: 'get',
                    url: endpoint
                });
                if( response.status === 200 ){
                    return response.data;
                }
                else {
                    return false;
                }
            } 
            catch( err ) {
                return false;
            }
        
        },

        getHomeData : async function(){

            const 
                API_URL = process.env.REACT_APP_API_URL,
                endpoint = API_URL + '/data/home.json'
            ;

            try {
                const response = await axios({
                    method: 'get',
                    url: endpoint
                });
                if( response.status === 200 ){
                    return response.data;
                }
                else {
                    return false;
                }
            } 
            catch( err ) {
                return false;
            }

        },

        getFooter : async function( siteID ){

            const 
                SERVER_URL = process.env.REACT_APP_SERVER_URL,
                endpoint = SERVER_URL + 'wp-json/bbprev/v1/footer?siteID=' + siteID
            ;

            try {
                const response = await axios({
                    method: 'get',
                    url: endpoint
                });
                if( response.status === 200 ){
                    return response.data;
                }
                else {
                    return false;
                }
            } 
            catch( err ) {
                return false;
            }

        }
        
    },

    ConsultaCEP : async function( cep ){

        const clear = cep.replace(/\D/g, "");

        try {
            const response = await axios({
                method: 'get',
                url: 'https://api.postmon.com.br/v1/cep/' + clear
            });
            if( response.status === 200 ){
                return response.data;
            }
            else {
                return false;
            }
        } 
        catch( err ) {
            return false;
        }

    },

    getCidades : async function ( UF ){
        const SERVER_URL = process.env.REACT_APP_API_URL;
        const response = await axios.get( SERVER_URL + '/data/ufcidade/' + UF + '.json' );
        return response.data === undefined ? false : response.data;
    },

    Post : async function( endpoint, pars ){

        // Data do send
        const formData = new FormData();
        this.buildFormData( formData, pars );

        // Request
        const response = await axios({
            method: 'post',
            url: API.URL + endpoint,
            data: formData
        });

        if( typeof response.data === 'string' ){
            return { 
                data: {
                    status: 'error',
                    message: 'Houve um problema técnico. Por favor, tente mais tarde'
                }
            };
        }
        else {
            return response;
        }

    },

    buildFormData : function( formData, data, parentKey ){
    
        if( data && typeof data === 'object' && !( data instanceof Date ) && !( data instanceof File ) ){
            Object.keys( data ).forEach( key => {
                this.buildFormData( formData, data[ key ], parentKey ? `${parentKey}[${key}]` : key );
            });
        }
        else {
            const value = data == null ? '' : data;
            formData.append( parentKey, value );
        }
    }

};
export { API };