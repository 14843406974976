import React, { Component } from 'react';
import Documentos from './Documentos.js';
import DemonstrativosFormularios from './DemonstrativosFormularios.js';
import StatusConvenio from './StatusConvenio.js';

export default class CentralInformacoes extends Component {

    render(){

        const { siteData } = this.props;

        return (
            <section id="central-de-informacoes" className="central-de-informacoes">
                <div className="wcf">
                    {/* <header>
                        <h3>{siteData.central.title}</h3>
                        <em>{siteData.central.excerpt}</em>
                    </header> */}
                    <Documentos siteData={siteData} />
                    <DemonstrativosFormularios siteData={siteData} />
                    <StatusConvenio siteData={siteData} />
                </div>
            </section>
        )

    }

}