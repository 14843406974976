import React, { Component } from 'react'

import { ReactComponent as ImgWhatsapp } from '../../assets/images/whats-write.svg';
import { ReactComponent as ImgFacebook } from '../../assets/images/facebook.svg';
import { ReactComponent as ImgLinkedin } from '../../assets/images/linkedin.svg';
import { ReactComponent as ImgInstagram } from '../../assets/images/instagram.svg';
import { ReactComponent as ImgTwitter } from '../../assets/images/twitter.svg';
import { ReactComponent as ImgSpotify } from '../../assets/images/spotify.svg';
import { ReactComponent as Mail } from "../../assets/images/mail.svg";
import { ReactComponent as Whatsapp } from "../../assets/images/whatsapp.svg";
import { ReactComponent as IcoPDF } from '../../assets/images/pdf-file.svg';
import { ReactComponent as IcoDownload } from '../../assets/images/download.svg';
import { ReactComponent as IcoDownloadGray } from '../../assets/images/download-gray.svg';
import { ReactComponent as IcoExternal } from '../../assets/images/external.svg';
import { ReactComponent as ArrowRight } from '../../assets/images/arrow-right.svg';
import { ReactComponent as ArrowRight2 } from '../../assets/images/arrow-right-2.svg';
import { ReactComponent as ArrowDown } from '../../assets/images/arrow-down.svg';
import { ReactComponent as icoPlus } from '../../assets/images/plus.svg';
import { ReactComponent as Calendar } from "../../assets/images/calendar.svg";
import { ReactComponent as PlayVideo } from "../../assets/images/youtube.svg";
import { ReactComponent as CrumbDiv } from "../../assets/images/crumb-div.svg";
import { ReactComponent as Chat } from "../../assets/images/chat.svg";
import { ReactComponent as Close } from "../../assets/images/close.svg";
import { ReactComponent as Error } from "../../assets/images/error.svg";
import { ReactComponent as ErrorBig } from "../../assets/images/error-big.svg";
import { ReactComponent as Success } from "../../assets/images/success.svg";
import { ReactComponent as Coins } from "../../assets/images/coins.svg";
import { ReactComponent as Diamond } from "../../assets/images/diamond.svg";
import { ReactComponent as Gear } from "../../assets/images/gear.svg";
import { ReactComponent as Balance } from "../../assets/images/balance.svg";
import { ReactComponent as Dolar } from "../../assets/images/dolar.svg";
import { ReactComponent as DolarOff } from "../../assets/images/dolar-off.svg";
import { ReactComponent as ZeroPercent } from "../../assets/images/zero-percent.svg";
import { ReactComponent as Receiver } from "../../assets/images/receiver.svg";
import { ReactComponent as DolarTransition } from "../../assets/images/dolar-transition.svg";
import { ReactComponent as Caddy } from "../../assets/images/caddy.svg";
import { ReactComponent as CoinsMsn } from "../../assets/images/coins-msn.svg";
import { ReactComponent as Deposit } from "../../assets/images/deposit.svg";
import { ReactComponent as People } from "../../assets/images/people.svg";
import { ReactComponent as Pause } from "../../assets/images/pause.svg";
import { ReactComponent as Smile } from "../../assets/images/smile.svg";
import { ReactComponent as Receiver2 } from "../../assets/images/receiver-2.svg";
import { ReactComponent as Receiver3 } from "../../assets/images/receiver-3.svg";
import { ReactComponent as Envelope } from "../../assets/images/envelope.svg";
import { ReactComponent as CoinsPage } from "../../assets/images/coins-page.svg";
import { ReactComponent as Receiver4 } from "../../assets/images/receiver-4.svg";
import { ReactComponent as Grenpa } from "../../assets/images/grenpa.svg";
import { ReactComponent as Grenma } from "../../assets/images/grenma.svg";
import { ReactComponent as Watch } from "../../assets/images/watch.svg";
import { ReactComponent as Star } from "../../assets/images/star.svg";
import { ReactComponent as TV } from "../../assets/images/tv.svg";
import { ReactComponent as Deposit2 } from "../../assets/images/deposit-2.svg";
import { ReactComponent as CashUp } from "../../assets/images/cash-up.svg";
import { ReactComponent as Palace } from "../../assets/images/palace.svg";
import { ReactComponent as Phone } from "../../assets/images/phone.svg";
import { ReactComponent as Phone2 } from "../../assets/images/phone2.svg";
import { ReactComponent as PartialCash } from "../../assets/images/partial-cash.svg";
import { ReactComponent as MomBaby } from "../../assets/images/mom-baby.svg";
import { ReactComponent as Family } from "../../assets/images/family.svg";
import { ReactComponent as PlantCash } from "../../assets/images/plant-cash.svg";
import { ReactComponent as MoneyBag } from "../../assets/images/money-bag.svg";
import { ReactComponent as MoneyGrowth } from "../../assets/images/money-growth.svg";
import { ReactComponent as Molecule } from "../../assets/images/molecule.svg";
import { ReactComponent as Calculator } from "../../assets/images/calculator.svg";
import { ReactComponent as Valid } from "../../assets/images/valid.svg";
import { ReactComponent as SuccessBig } from "../../assets/images/success-big.svg";
import { ReactComponent as Barcode } from "../../assets/images/barcode.svg";
import { ReactComponent as Bank } from "../../assets/images/bank.svg";
import { ReactComponent as Awaiting } from "../../assets/images/awaiting.svg";
import { ReactComponent as Lupa } from "../../assets/images/lupa.svg";
import { ReactComponent as Trash } from "../../assets/images/trash.svg";
import { ReactComponent as Upload } from "../../assets/images/upload.svg";
import { ReactComponent as Initial } from "../../assets/images/initial.svg";

export class Icons extends Component {

    components = {
        'whatswrite': ImgWhatsapp,
        'facebook': ImgFacebook,
        'linkedin': ImgLinkedin,
        'instagram': ImgInstagram,
        'twitter': ImgTwitter,
        'spotify': ImgSpotify,
        'mail': Mail,
        'whatsapp': Whatsapp,
        'pdf': IcoPDF,
        'download': IcoDownload,
        'downloadgray': IcoDownloadGray,
        'external': IcoExternal,
        'arrowright': ArrowRight,
        'arrowright2': ArrowRight2,
        'arrowdown': ArrowDown,
        'plus': icoPlus,
        'calendar': Calendar,
        'playvideo': PlayVideo,
        'crumbdiv': CrumbDiv,
        'chat': Chat,
        'error': Error,
        'error-big': ErrorBig,
        'success': Success,
        'success-big': SuccessBig,
        'coins': Coins,
        'diamond': Diamond,
        'gear': Gear,
        'balance': Balance,
        'dolar': Dolar,
        'dolar-off': DolarOff,
        'zero-percent': ZeroPercent,
        'receiver': Receiver,
        'dolar-transition': DolarTransition,
        'caddy': Caddy,
        'coins-msn': CoinsMsn,
        'deposit': Deposit,
        'people': People,
        'pause': Pause,
        'smile': Smile,
        'receiver-2': Receiver2,
        'receiver-3': Receiver3,
        'envelope': Envelope,
        'coins-page': CoinsPage,
        'receiver-4': Receiver4,
        'grenpa': Grenpa,
        'grenma': Grenma,
        'watch': Watch,
        'star': Star,
        'tv': TV,
        'deposit-2': Deposit2,
        'cash-up': CashUp,
        'palace': Palace,
        'phone': Phone,
        'phone2': Phone2,
        'partial-cash': PartialCash,
        'mom-baby': MomBaby,
        'family': Family,
        'plant-cash': PlantCash,
        'money-bag': MoneyBag,
        'money-growth': MoneyGrowth,
        'molecule': Molecule,
        'calculator': Calculator,
        'close': Close,
        'valid': Valid,
        'barcode': Barcode,
        'bank': Bank,
        'awaiting': Awaiting,
        'lupa': Lupa,
        'trash': Trash,
        'upload': Upload,
        'initial': Initial
    };
    
    render() {

        let ImgSrc;
        if( this.components[this.props.tag] === undefined ){
            ImgSrc = this.components[ 'external' ];
        }
        else {
            ImgSrc = this.components[ this.props.tag ];
        }

       return <ImgSrc />
    
    }

}

export default Icons;