import React, { Component } from 'react';
import { Icons } from "../helpers/Icons";

export default class Beneficios extends Component {

    render(){

        const { data } = this.props;
        
        return(
            <div id="beneficios" className="beneficios">
                <div className="wcf">
                    <header>
                        <h3>{data.title}</h3>
                        <div>
                            <p>{data.description}</p>
                        </div>
                    </header>
                    <ul>
                        {data.items.map(item =>
                        <li key={item.key}>
                            <i className="icon"><Icons tag={item.icon} /></i>
                            <h4>{item.label}</h4>
                        </li>
                        )}
                    </ul>
                </div>
            </div>
        );
        
    }
}
