import React, { Component } from 'react';
import Icons from '../helpers/Icons';

export class TimeDeEspecialistas extends Component {

    render(){

        return(
            <section className="time-de-especialistas">
                <div>
                    <h3>Fale com a gente! Por onde preferir</h3>
                    <ul>
                        <li>
                            <Icons tag="whatsapp" />
                            <em><a href="https://api.whatsapp.com/send/?phone=556131810179&text=Oi!&app_absent=0" title="Mandar mensagem no whatsapp" target="_blank" rel="noreferrer">Clique aqui</a> <br />e mande uma mensagem para <br />o nosso WhatsApp <a href="https://api.whatsapp.com/send/?phone=556131810179&text=Oi!&app_absent=0" title="Mandar mensagem no whatsapp" target="_blank" rel="noreferrer">61 3181-0179</a></em>
                        </li>
                        <li>
                            <Icons tag="mail" />
                            <em>Acesse nosso <br /><a href="/fale-conosco" title="Enviar mensagem">Fale Conosco</a> <br />e encaminhe sua dúvida<br /></em>
                        </li>
                        <li>
                            <Icons tag="phone2" />
                            <em>Entre em contato pelo <br /><a href="tel:+5508006014554" title="Fazer ligação">0800 601 4554</a> ou <a href="tel:+5530043444" title="Fazer ligação">3004 3444</a></em>
                            <strong>De segunda a sexta das 8h às 18h</strong>
                        </li>
                    </ul>
                </div>
            </section>
        )

    }

}

export default TimeDeEspecialistas;