import React, { Component } from 'react';
import { NavLink } from "react-router-dom";
import Icons from '../helpers/Icons';
import StickedPlans from './StickedPlans';

export class Planos extends Component {

    render() {

        return (
            <section id="planos" className="lista-planos">
                <div className="wcf">
                    <h3>Conheça os planos</h3>
                    <StickedPlans />
                    <NavLink to="./planos" className="arrow-right">
                        Mais planos
                        <Icons tag="arrowright" />
                    </NavLink>
                </div>
            </section>
        )
   
    }

}

export default Planos;