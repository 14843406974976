//import { isSafari } from 'react-device-detect';

export function getBasename(){
    if( window.siteKey.indexOf( '<' ) === 0 ){
        let 
            peaces = window.location.pathname.split( '/' ),
            site = peaces.shift()
        ;
        if( site === '' ){
            site = peaces.shift();
        }
        return site;
    }
    else {
        return window.siteKey;
    }
};

export function imageURL( url ){
    return url;
}

export function normalizeString( str ){

    str = str.replace( /[ÀÁÂÃÄÅ]/, 'A' )
    str = str.replace( /[àáâãäå]/, 'a' )
    str = str.replace( /[ÈÉÊË]/, 'E' )
    str = str.replace( /[í]/, 'i' )
    str = str.replace( /[ôõó]/, 'o' )
    str = str.replace( /[ú]/, 'u' )
    str = str.replace( /[Ç]/, 'C' )
    str = str.replace( /[ç]/, 'c' )

    return str.replace( /[^a-z0-9 ]/gi, '' )

}

export function onlyNumbers( string ){
    return string.replace( /\D/g, '' )
}

export function stringSearch( source, string ){

    source = normalizeString( source.toUpperCase() );
    string = normalizeString( string.toUpperCase() );

    return source.indexOf( string ) >= 0;

}

// URL para posts do Blog BBP
export function BlogBBPLink( item ){

    const type = item.type !== undefined ? item.type : item.post_type;
    const type_slug = ( type === 'curadoria' ? 'curadoria' : item.custom.editoria.slug );
    
    return '/blogbbp/' + type_slug + '/' + item.custom.slug; 
}

export function validateEmail( email ){

    let lastAtPos = email.lastIndexOf( '@' );
    let lastDotPos = email.lastIndexOf( '.' );

    if( !( lastAtPos < lastDotPos && lastAtPos > 0 && email.indexOf( '@@' ) === -1 && lastDotPos > 2 && ( email.length - lastDotPos ) > 2 ) ){
        return false;
    }

    return true;

}

// Str pad
function pad( number ){
    return number.toString().length === 2 ? number.toString() : ( '0' + number.toString() );
}

// Formating date
export function formatDate( date ){
    return pad( date.dayOfMonth ) + '/' + pad( date.monthValue ) + '/' + date.year;
}

export function maskCep( cep ){
    
    // Normalize and format
    cep = cep.toString();
    if( cep.length === 8 ){
        var peaces = cep.split( '' );
        cep = peaces.slice( 0, 2 ).join( '' ) + '.' + peaces.slice( 2, 5 ).join( '' ) + '-' + peaces.slice( 5 ).join( '' );
    }
    return cep;
}

export function validateCEP( cep ){

    // Normalize and format
    cep = maskCep( cep );

    // Validate
    var objER = /^[0-9]{2}.[0-9]{3}-[0-9]{3}$/;
    return objER.test( cep );

}

export function ClearMoney( value ){
    if( value === '' ) return 0;  
    value = value.toString().replace( 'R$', '' );    
    value = value
        .split( '.' ).join( '' )
        .split( ' ' ).join( '' )
        .split( ',' ).join( '.' )
    ;    
    value = parseFloat( value );
    return value <= 0 ? 0 : value;
}

export function cpfMask( value ){

    if( value === null || value === false || value === '' ) return '';

    return value
      .replace(/\D/g, '') // substitui qualquer caracter que nao seja numero por nada
      .replace(/(\d{3})(\d)/, '$1.$2') // captura 2 grupos de numero o primeiro de 3 e o segundo de 1, apos capturar o primeiro grupo ele adiciona um ponto antes do segundo grupo de numero
      .replace(/(\d{3})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d{1,2})/, '$1-$2')
      .replace(/(-\d{2})\d+?$/, '$1') // captura 2 numeros seguidos de um traço e não deixa ser digitado mais nada
}

export function emailMask( email ){

    if( !email || email === '' ) return '';

    var maskedEmail = email.replace(/([^@.])/g, "*").split( '' );
    var previous = '';
    
    for( let i = 0; i < maskedEmail.length; i++ ){
        if( i <= 1 || previous === "." || previous === '@' ){
            maskedEmail[ i ] = email[ i ];
        }
        previous = email[ i ];
    }

    return maskedEmail.join( '' );

}

export function calculaIdade( data ){

    let peaces, dia, mes, ano;
    if( data.indexOf( '/' ) > 0 ){
        peaces = data.split( '/' );
        dia = peaces[ 0 ];
        mes = peaces[ 1 ];
        ano = peaces[ 2 ];
    }
    else {
        peaces = data.split( '-' );
        dia = peaces[ 2 ];
        mes = peaces[ 1 ];
        ano = peaces[ 0 ];
    } 

    var d = new Date(),
        ano_atual = d.getFullYear(),
        mes_atual = d.getMonth() + 1,
        dia_atual = d.getDate(),
        ano_aniversario = +ano,
        mes_aniversario = +mes,
        dia_aniversario = +dia,
        quantos_anos = ano_atual - ano_aniversario
    ;

    if( mes_atual < mes_aniversario || ( mes_atual === mes_aniversario && dia_atual < dia_aniversario ) ){
        quantos_anos--;
    }

    return quantos_anos < 0 ? 0 : quantos_anos;

}

export default getBasename;
