import { Component } from 'react';

export class LoaderMask extends Component {

	constructor( props ){

		super( props );

		this.getMask = this.getMask.bind( this );
		this.open = this.open.bind( this );
		this.close = this.close.bind( this );

	}

	getMask(){

		// Get older
		var mask = document.getElementById( 'loadermask' );

		if( mask === null ){

			// Main div
			mask = document.createElement( 'div' );
			mask.id = 'loadermask';
			mask.style.position = 'fixed';
			mask.style.display = 'flex';
			mask.style.alignItems = 'center';
			mask.style.justifyContent = 'center';
			mask.style.top = 0;
			mask.style.right = 0;
			mask.style.bottom = 0;
			mask.style.left = 0;
			mask.style.backgroundColor = 'rgba(0,0,0,0.3)';
			mask.style.zIndex = '99999';
			mask.style.transition = 'opacity 500ms';
			mask.style.transform = 'translateX(-100%)';
			mask.style.opacity = '0';
			document.body.appendChild( mask );

			// Spinner
			let spinner = document.createElement( 'div' );
			spinner.style.width = '64px';
			spinner.style.height = '64px';
			spinner.style.borderRadius = '5px';
			spinner.style.backgroundColor = '#fff';
			spinner.style.backgroundImage = 'url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjwhLS0gR2VuZXJhdG9yOiBBZG9iZSBJbGx1c3RyYXRvciAxNi4wLjAsIFNWRyBFeHBvcnQgUGx1Zy1JbiAuIFNWRyBWZXJzaW9uOiA2LjAwIEJ1aWxkIDApICAtLT4NCjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+DQo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4Ig0KCSB3aWR0aD0iMzhweCIgaGVpZ2h0PSIzOHB4IiB2aWV3Qm94PSIwIDAgMzggMzgiIGVuYWJsZS1iYWNrZ3JvdW5kPSJuZXcgMCAwIDM4IDM4IiB4bWw6c3BhY2U9InByZXNlcnZlIj4NCjxnPg0KCTxnIHRyYW5zZm9ybT0idHJhbnNsYXRlKDEgMSkiPg0KCQk8Y2lyY2xlIGZpbGw9Im5vbmUiIHN0cm9rZT0iIzIxNTI5NyIgc3Ryb2tlLXdpZHRoPSIyIiBzdHJva2Utb3BhY2l0eT0iMC41IiBjeD0iMTgiIGN5PSIxOCIgcj0iMTgiLz4NCgkJPHBhdGggZmlsbD0ibm9uZSIgc3Ryb2tlPSIjMjE1Mjk3IiBzdHJva2Utd2lkdGg9IjIiIGQ9Ik0zNiwxOGMwLTkuOTQtOC4wNjEtMTgtMTgtMTgJCSI+DQo8YW5pbWF0ZVRyYW5zZm9ybSAgZmlsbD0icmVtb3ZlIiB0eXBlPSJyb3RhdGUiIHJlc3RhcnQ9ImFsd2F5cyIgY2FsY01vZGU9ImxpbmVhciIgYWRkaXRpdmU9InJlcGxhY2UiIGFjY3VtdWxhdGU9Im5vbmUiIHJlcGVhdENvdW50PSJpbmRlZmluaXRlIiBkdXI9IjFzIiB0bz0iMzYwIDE4IDE4IiBmcm9tPSIwIDE4IDE4IiBhdHRyaWJ1dGVOYW1lPSJ0cmFuc2Zvcm0iPg0KCQkJCTwvYW5pbWF0ZVRyYW5zZm9ybT4NCgkJPC9wYXRoPg0KCTwvZz4NCjwvZz4NCjwvc3ZnPg0K")';
			spinner.style.backgroundRepeat = 'no-repeat';
			spinner.style.backgroundPosition = 'center center';
			spinner.style.boxShadow = '0px 0px 12px 2px rgba(0,0,0,0.2)';
			mask.appendChild( spinner );

		}

		return mask;
	
	}

	open(){

		var mask = this.getMask();
		mask.style.transform = 'none';
		mask.style.opacity = '100';
	
	}

	close(){

		var mask = this.getMask();
		mask.style.transform = 'translateX(-100%)';
		mask.style.opacity = '0';

	}

	render(){
		return null;
	}

}

export default LoaderMask