import React, { Component } from 'react';
import Icons from '../helpers/Icons.js';
import { onlyNumbers } from '../helpers/Common.js';

export class CanaisAtendimento extends Component {

    render(){

        const { data } = this.props;
        if( data === undefined || data.items.length === 0 ) return null;

        var items = [];
        for( let ti = 0; ti < data.items.length; ti++ ){

            // Item
            const item = data.items[ ti ];

            // Check if is not empty
            if( item.value === '' ){
                continue;
            }

            // Format data
            if( item.key === 'email' ){
                item.url = 'mailto:' + item.value;
                item.label = 'Email';
            }
            else if( item.key === 'chat' ){
                item.url = item.value;
                item.label = 'Chat';
            }
            else if( item.key === 'phone' ){
                item.url = 'tel:' + item.value;
                item.label = item.value;
            }
            else if( item.key === 'whatsapp' ){
                item.url = 'https://wa.me/55' + item.value;
                item.label = 'WhatsApp';
            }

            items.push( item );

        }
        if( !items.length ) return null;
        
        let className = 'canais-atendimento count-' + items.length;
        
        return(
            <div className={className}>
                <div className="inner">
                    <h4>Fale com a gente <br></br>por onde preferir</h4>
                    <ul>
                        {items.map( item => {
                            
                            if( item.key === 'phone' ){
                            
                                const numbers = item.value.split( '|' );
                                
                                console.info('item.icon');
                                console.log(item.icon);
                                
                                
                                return(
                                <li key={item.key}>
                                    <React.Fragment>
                                        <i className="icon">
                                            <Icons tag={item.icon} />
                                        </i>
                                        <nav>
                                            {numbers.map( ( number, kn ) => {
                                                return (
                                                <a key={kn} href={'tel:'+onlyNumbers(number)} title="Fazer ligação" target="_blank" rel="noreferrer">
                                                    {number}
                                                </a>)
                                            })}
                                        </nav>
                                    </React.Fragment>
                                </li>
                                )
                            }
                            else if( item.key === 'whatsapp'){

                                let number = item.url.split( '/' ).pop();
                                if( number.substr( 0, 2 ) === '55' ){
                                    number = number.substr( 2 );
                                }
                                const prefix = number.substr( 0, 2 );
                                number = number.substr( 2 );

                                if( number.length === 8 ){
                                    number = number.substr( 0, 4 ) + '-' + number.substr( 4 );
                                }
                                else {
                                    number = number.substr( 0, 5 ) + '-' + number.substr( 5 );
                                }
                                const numberShow = '(' + prefix + ') ' + number;
                                
                                console.info('item.icon');
                                console.log(item.icon);
                                
                                return(
                                <li key={item.key}>
                                    <i className="icon">
                                        <Icons tag={item.icon} />
                                    </i>
                                    <strong>
                                        Mande uma mensagem para o nosso WhatsApp: <br /><a href={item.url} title={item.label}target="_blank" rel="noreferrer">{numberShow}</a>
                                    </strong>
                                </li>)
                            }
                            else {

                                return(
                                <li key={item.key}>
                                    <i className="icon">
                                        <Icons tag={item.icon} />
                                    </i>
                                    <a href={item.url } title={item.label}target="_blank" rel="noreferrer">
                                        {item.label}
                                    </a>
                                </li>)
                                
                            }

                        })}
                    </ul>
                </div>
            </div>
        )
    }

}

export default  CanaisAtendimento;