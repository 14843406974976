import React, { Component } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import { withRouter } from "react-router";
import ReactHtmlParser from 'react-html-parser';
import LoaderMask from '../helpers/LoaderMask';
import scrollTo from '../helpers/ScrollTo';
import $ from 'jquery';

export class BeneficiosList extends Component {

    static propTypes = {
        match: PropTypes.object.isRequired,
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired
    };

    constructor( props ){

        super( props )

        this.state = {
            isLoaded: false,
            isMobile: false,
            perPage: 12,
            paged: 1,
            segmento : false,
            loadingItems: true,
            totalPages : 1,
            showActives: false,
            items: [],
            mask: new LoaderMask(),
        }

        this.bindPager = this.bindPager.bind( this );
        this.requestItems = this.requestItems.bind( this );
    
    }

    componentDidMount(){

        // Request items
        this.requestItems();

        // Select de editorias
        this.getEditorias();
    }

    bindPager( e ){

        e.preventDefault();

        const { paged } = this.state;
        this.setState({
            paged: ( paged + 1 )
        }, this.requestItems );

    }

    requestItems(){

        const { perPage, paged, segmento  } = this.state;
        
        var URL = process.env.REACT_APP_SERVER_URL + 'wp-json/wp/v2/clube-de-beneficios?per_page='+ perPage + '&page=' + paged;
    
        // Open mask
        this.state.mask.open();

        // Loading
        this.setState({ loadingItems: true });

        // Category
        if( segmento ){
            URL += '&segmento=' + segmento;
        }
        
        // Request
        axios
            .get( URL )
            .then( res => {

                // Insert new items
                let { items } = this.state;
                const nextFirstIndice = items.length;
                items = items.concat( res.data );

                this.setState(
                    {
                        isLoaded: true,
                        loadingItems: false,
                        items: items,
                        totalPages: res.headers[ 'x-wp-totalpages' ]
                    }, 
                    () => {

                        // Close mask
                        this.state.mask.close();

                        // Scroll to next intems
                        const nextTarget = $( '.beneficios-list .item-list' ).eq( nextFirstIndice );
                        scrollTo( nextTarget, 30 );
                    
                    }
                );
            })
            .catch(err => console.log(err))
        ;

    }

    // Select de editorias
    getEditorias(){

        const 
            SERVER_URL = process.env.REACT_APP_SERVER_URL,
            { match } = this.props
        ;
        axios
            .get( SERVER_URL + 'wp-json/wp/v2/' + ( match.params.category === 'curadoria' ? 'categs' : 'editorias' ) + '?per_page=100' )
            .then( res => {
                
                let items = [{
                    id : 0,
                    text : 'Todas as categorias'
                }];
                for( let i = 0; i < res.data.length; i++ ){
                    items.push({
                        id : res.data[ i ].id,
                        text : res.data[ i ].name
                    });
                }

                this.setState({
                    isLoaded: ( this.state.items.length ? true : false ),
                    editorias: items
                });
            
            })
            .catch(err => console.log(err))
        ; 

    }

    render() {

        const { isLoaded, loadingItems, items, totalPages, paged } = this.state;
        if( !isLoaded || !items.length ) return null;

        console.info('totalPages');
        console.log(totalPages);

        return (
            <section id="beneficios-list" className="beneficios-list">
                <div>
                    <header>
                        <h3>Outros benefícios</h3>
                        {/* <em>Conteúdos exclusivos desenvolvidos especialmente para você.</em> */}
                    </header>
                    <div className="list">
                        {items.map(( item ) => {
                            return (
                            <div className="item-list" key={ 'tsrbnf-' + item.id }>
                                <h4>{item.title.rendered}</h4>
                                <span className="texts">
                                    {item.custom.logo && 
                                    <span className="logo-wrapper">
                                        <img src={item.custom.logo} alt={item.title.rendered}/>
                                    </span>}
                                    {ReactHtmlParser(item.excerpt.rendered)}
                                </span>
                                <a href={item.custom.url} target="_blank" rel="noreferrer noopener">
                                    <strong>Saiba mais</strong>
                                </a>
                            </div>
                            );
                        })}
                    </div>
                    { ( totalPages > 1 && paged < totalPages ) &&
                    <button type="button" className="styled default pager" onClick={this.bindPager}>{loadingItems ? 'Carregando...' : 'Mais benefícios'}</button>}
                </div>
            </section>
        )
   
    }

}

export default withRouter( BeneficiosList );